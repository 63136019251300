<button class="closebtn" mat-icon-button (click)="closeClick()">
  <mat-icon>cancel</mat-icon>
</button>
<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content>
  @if (data.showInput) {
    <div>
      <mat-form-field>
        <input matInput name="dialog" [(ngModel)]="data.inputValue">
      </mat-form-field>
    </div>
  }
  @if (data.desc) {
    <div>
      <p>{{data.desc}}</p>
    </div>
  }
  @if (data.html) {
    <div [innerHTML]="data.html"></div>
  }
</mat-dialog-content>
<mat-dialog-actions style="display: flex; justify-content: space-around; align-items: center">
  @if (data.leftBtnTitle) {
    <button mat-raised-button color="{{data.leftBtnColor || 'warn'}}" (click)="onLeftClick()">
      @if (data.leftBtnIcon) {
        <mat-icon>{{data.leftBtnIcon}}</mat-icon>
      }
      {{data.leftBtnTitle}}
    </button>
  }
  @if (data.rightBtnTitle) {
    <button mat-raised-button color="{{data.rightBtnColor || 'primary'}}" (click)="onRightClick()">
      @if (data.rightBtnIcon) {
        <mat-icon>{{data.rightBtnIcon}}</mat-icon>
      }
      {{data.rightBtnTitle}}
    </button>
  }
</mat-dialog-actions>
