import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../../common/services/auth.service';
import { MetaInfoService } from '../../common/services/meta-info.service';
import { RequesterService } from '../../common/services/requester.service';

enum mode {
	SINGLE,
	GROUP,
}

@Component({
	selector:    'app-payment-delete',
	templateUrl: './payment-delete.component.html',
	styleUrls:   ['./payment-delete.component.css'],
})
export class PaymentDeleteComponent implements OnInit {

	disabledButton = true;
	disabled;
	color;
	checked;
	private groupId = this.currentRout.snapshot.params.groupId;
	private paymentId = this.currentRout.snapshot.params.paymentId;
	private refundId = this.currentRout.snapshot.params.refundId;
	private type = this.currentRout.snapshot.data.type;

	title = '';

	creationMOde = this.groupId ? mode.GROUP : mode.SINGLE;

	private CONFIG;

	constructor (
		private auth: AuthService,
		private requester: RequesterService,
		private router: Router,
		private currentRout: ActivatedRoute,
		private metaInfo: MetaInfoService,
	) {

		switch (this.type) {
			case 'payment':
				this.CONFIG = {
					methodShow: 'deleteBill',
					navigate:   ['bills', 'list'],
					params:     [this.paymentId],
					url:        `/payments/${this.paymentId}`,
					title:      'Видалити платіж',
				};
				break;
			case 'groupPayment':
				this.CONFIG = {
					methodShow: 'deleteGroupBill',
					navigate:   ['groups', this.groupId],
					params:     [this.groupId, this.paymentId],
					url:        `/groups/${this.groupId}/payments/${this.paymentId}`,
					title:      'Видалити платіж'
				};
				break;
			case 'groupRefund':
				this.CONFIG = {
					methodShow: 'deleteGroupBill',
					navigate:   ['groups', this.groupId],
					params:     [this.groupId, this.refundId],
					url:        `/groups/${this.groupId}/refund/${this.refundId}`,
					title:      'Видалити повернення'
				};
		}

		this.title = this.CONFIG.title;
	}

	ngOnInit () {
		this.metaInfo.setData({
			rightMenu: {
				icon: 'cancel',
				href: this.CONFIG.url,
			},
			title: this.CONFIG.title,
		});
	}

	toggleDelete () {
		this.disabledButton = !this.disabledButton;
	}

	deletePayment () {
		this.metaInfo.showPreloader(true);
		this.requester[this.CONFIG.methodShow](...this.CONFIG.params)
		.then(() => {
			this.router.navigate(this.CONFIG.navigate);
		})
		.catch(err => {
			// todo df change after finding
			// this.metaInfo.showError(err);

			this.metaInfo.showPreloader(false);

			if (err && err.code === 'NEED_AUTH') {
				this.auth.logout();

				return this.router.navigate(['login']);
			}
			console.log(err);
		});
	}
}
