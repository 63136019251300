<mat-card class="float-card">
  <mat-card-content>
    <div class="center-text">
      <mat-button-toggle-group (change)="changeList($event.value)" name="fontStyle" aria-label="Font Style">
        <mat-button-toggle [checked]="true" value="all">Всі</mat-button-toggle>
        <mat-button-toggle value="debts">З боргами</mat-button-toggle>
        <mat-button-toggle value="drafts">Чернетки</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <mat-selection-list>
      @for (bill of billList; track bill) {
        <mat-list-item
          class="border list-slim" [ngClass]="bill.resolved ? '' : 'app-warning'"
          routerLink="/{{getLink(bill)}}/{{bill.id}}">
          <mat-icon class="list-icon" matListItemIcon>{{getTypeIcon(bill)}}</mat-icon>
          <mat-icon class="list-icon" matListItemIcon>{{getStateIcon(bill)}}</mat-icon>
          <h3 matListItemTitle>{{bill.name}}</h3>
          <p matListItemLine style="margin-bottom: 0; text-align: center">
            <!--<mat-chip-list>-->
            <!--<mat-chip>O</mat-chip>-->
            <!--<mat-chip>T</mat-chip>-->
            <!--<mat-chip color="primary" selected="true">P</mat-chip>-->
            <!--<mat-chip color="accent" selected="true">A</mat-chip>-->
          <!--</mat-chip-list>-->
          {{hs.formatDateTime(bill.date)}}
        </p>
        <span matListItemMeta>
          <mat-icon class="icon-vert-align">{{ bill.link ? 'share' : '' }}</mat-icon>
          <span style="vertical-align: 5px; color: red; font-size: 18px; font-weight: bolder;">{{ bill.allSum | money }}</span>
          <!--                <span style="color: red; font-size: 18px; font-weight: bolder;">{{ bill.allSum | money }}&#160;UAH</span>-->
        </span>
      </mat-list-item>
    }
  </mat-selection-list>
</mat-card-content>
</mat-card>
@if (!billList || !billList.length) {
  <mat-card class="float-card">
    <mat-card-header>
      <mat-card-title>Ви все ще не маєте жодної оплати</mat-card-title>
    </mat-card-header>
  </mat-card>
}
<button class="fixed-action-btn" routerLink="/payments/create" mat-fab color="primary" aria-label="Додати оплату">
  <mat-icon>add</mat-icon>
</button>
