import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';

import { DialogComponent } from '../../common/components/dialog/dialog.component';
import { UserModelService } from '../../common/models/user-model.service';
import { AuthService } from '../../common/services/auth.service';
import { BillService, TYPES } from '../../common/services/bill.service';
import { HelperService } from '../../common/services/helper.service';
import { MetaInfoService } from '../../common/services/meta-info.service';
import { RequesterService } from '../../common/services/requester.service';
import { StorageService } from '../../common/services/storage.service';

export interface DialogData {
	yes: boolean;
	name: string;
}

export interface Element {
	id: number;
	name: string;
	cost: number;
	quantity: number;
	sumBD: number;
	disSum: number;
	disPercent: number;
	gSum: number;
	resolve?: string;  // поле которое было высчетанно автоматически
}

enum mode {
	SINGLE,
	SHARE,
	GROUP,
	GROUP_SHARE,
}

@Component({
	selector:    'app-payment-show',
	templateUrl: './payment-show.component.html',
	styleUrls:   ['./payment-show.component.css']
})
export class PaymentShowComponent implements OnInit {

	private groupId = this.currentRout.snapshot.params.groupId;
	private paymentId = this.currentRout.snapshot.params.paymentId;
	private shareId = this.currentRout.snapshot.params.shareUrl;
	creationMOde = mode.SINGLE;

	private submenu;
	private config;

	private CONFIG = [
		{
			localStorageName: 'tmpPayment',
			methodShow:       'getFullBill',
			navigate:         [],
			params:           [this.currentRout.snapshot.params.paymentId],
			refundNavigate:   ['bills', this.currentRout.snapshot.params.paymentId],
			backNavigate:     '/bills/list',
		}, {
			localStorageName: 'tmpBill',
			methodShow:       'getShareBill',
			navigate:         [],
			params:           [this.shareId],
			backNavigate:     '/bills/list',
		}, {
			localStorageName: 'tmpGroupBill',
			methodShow:       'getGroupBillFull',
			navigate:         ['group', this.currentRout.snapshot.params.groupId],
			params:           [
				this.currentRout.snapshot.params.groupId,
				this.currentRout.snapshot.params.paymentId
			],
			refundNavigate: ['groups', this.groupId],
			backNavigate:   `/groups/${this.groupId}`
		}, {
			localStorageName: 'tmpShareGroupBill',
			methodShow:       'getShareGroupBillFull',
			navigate:         ['group', this.currentRout.snapshot.params.groupId],
			params:           [
				this.shareId,
				this.paymentId
			],
			refundNavigate: ['groups', this.groupId, 'bills', this.paymentId],
			backNavigate:   `/share/${this.shareId}/group`
		}
	];

	bill;
	debtorsList;
	private checkSumBill;
	private allSum;
	private iconBill;
	private showTable = 1;
	dataSource;
	displayedColumns = [];
	displayedDefColumns = ['position', 'name', 'cost', 'quantity', 'gSum'];
	payments = [];
	paymentsSum = 0;
	allSumOfRows = 0;
	gSum = 0;
	gTip = 0;
	gAllSum = 0;
	checkAllSumBill = false;
	checkPayments = false;
	iconPayment = '';
	activeTab = 0;
	maxSpendSum = 0;
	addToUser = false;

	name: string;

	showError = false;
	errorMessage: any;
	TYPES = TYPES;

	constructor (
		private requester: RequesterService,
		private router: Router,
		private currentRout: ActivatedRoute,
		private billService: BillService,
		private userModelService: UserModelService,
		private auth: AuthService,
		private metaInfo: MetaInfoService,
		public  dialog: MatDialog,
		public  storage: StorageService,
		public  hs: HelperService,
	) {

		if (this.currentRout.snapshot.params.groupId) {
			this.creationMOde = mode.GROUP;

			// шара через групу
		} else if (this.shareId && this.paymentId) {
			this.creationMOde = mode.GROUP_SHARE;

			// шара через рахунок
		} else if (this.shareId) {
			this.creationMOde = mode.SHARE;
		}

		this.config = this.CONFIG[this.creationMOde];
	}

	ngOnInit () {
		this.metaInfo.showPreloader(true);

		// this.config = this.CONFIG[this.creationMOde];

		this.metaInfo.setTitle('Loading...');

		this.requester[this.config.methodShow](...this.config.params)
		.then(async data => {

			this.metaInfo.showPreloader(false);

			if (data && data.users) {
				data.users = data.users.map(user => {
					user.name = user.name || 'участник_' + (+user.id + 1);

					return user;
				});
			}

			this.bill = data;

			this.displayedColumns = this.displayedColumns.concat(this.bill.type === TYPES.BILL ? this.displayedDefColumns : []);

			if (this.creationMOde === mode.SHARE) {

				// якщо чек вже прив'язаний то переходим на нього
				if (data.id) {
					return this.router.navigate(['payments', data.id]);
				}

				const user = this.userModelService.getUserFromCache();
				if (user) {
					this.addToUser = true;

					this.openDialog();
				}
			}

			this.metaInfo.setData({
				leftMenu: {
					icon: 'keyboard_backspace',
					href: this.config.backNavigate
				},
				rightMenu: this.getSubMenu(data.rights),
				title:     this.bill.type === TYPES.BILL ? 'Рахунок' : 'Платіж'
			});

			this.displayedColumns = this.displayedColumns.concat(data.users.map(u => 'u' + u.id));
			this.allSum = this.processing(data);

			this.paymentsSum = this.bill.payments.reduce((acc, item) => {
				return acc + item;
			},                                           0);
			this.payments = this.bill.payments;
			this.debtorsList = this.getUserDebtor();

			// проверяем правильность ввседенных данных и расчитанных
			this.gSum = this.allSum.gSum.reduce((a, i) => i + a, 0);
			this.gTip = this.allSum.tipSum.reduce((a, i) => i + a, 0);
			this.gAllSum = this.gSum + this.gTip;

			this.checkSumBill = this.gSum === this.bill.sum;
			this.iconBill = this.checkSumBill ? 'chrome_reader_mode' : 'clear';

			this.checkAllSumBill = this.gAllSum === (this.bill.allSum || this.bill.tip + this.bill.sum);

			// перевіряємо чи зійшлист всі суми: чек, строки та оплати
			this.checkPayments = (this.bill.allSum || this.bill.tip + this.bill.sum) === this.paymentsSum;
			this.iconPayment = this.checkPayments ? 'account_balance_wallet' : 'clear';

			this.dataSource = new MatTableDataSource<Element>(this.bill.rows);

			this.maxSpendSum = Math.max(...this.allSum.gSum);
		})
		.catch(err => {
			this.metaInfo.showError(err);
		});
	}

	openDialog (): void {
		const dialogRef = this.dialog.open(DialogComponent, {
			width: '450px',
			data:  {
				type:          'addBill',
				title:         'Загальний рахунок',
				desc:          'Бажаєте додати цей рахунок до свого облікового запису?',
				rightBtnTitle: 'Так',
				leftBtnTitle:  'Ні дякую',
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			// console.log('The dialog was closed', result);

			if (result && result.type === 'rightClick') {
				this.requester.addSharedBill(this.shareId)
				.then(async response => {
					await this.storage.del('addSharaLater');

					return this.router.navigate(['payments', response.billNumber]);
				})
				.catch(async err => {

					await this.storage.set('addSharaLater', { type: 'bill', id: this.shareId });

					if (err && err.code === 'NEED_AUTH') {
						return this.router.navigate(['login']);
					}

					throw err;
				});
			} else {
				this.storage.del('addSharaLater');
			}
		});
	}

	processing (data): { gSum: Array<any>, tipSum: Array<any>, rowsOfCost: Array<any>, variety: number } {
		// массив сумм по каждой позиции
		const rowsOfCost = data.rows.map(row => {
			return row.amounts;
		});

		// масив общих сум по юзерам
		const gSum = rowsOfCost.reduce((acc, amounts) => {

			return acc.map((item, index) => {
				return item + (amounts[index] || 0);
			});
		},                           data.users.map(() => 0));

		return { gSum, tipSum: data.userTips, rowsOfCost, variety: 0};
	}

	getUserDebtor () {
		const result = Object.keys(this.bill.debtors).map(userId => {
			const user = this.bill.users.find(user => {

				return user.id === +userId || user.name === userId; // old logic, keep it for compatibility;
			});

			return Object.keys(this.bill.debtors[userId].debtors || { }).map(debtorId => {

				const debtor = this.bill.users.find(user => {

					return user.id === +debtorId || user.name === debtorId; // old logic, keep it for compatibility;
				});

				return {
					userId:     user.id,
					userName:   user.name,
					debtorId:   debtor.id,
					debtorName: debtor.name,
					sum:        this.bill.debtors[userId].debtors[debtorId].sum
				};
			});
		});

		return [].concat(...result);
	}

	refund (userFrom, userTo, amount) {
		return this.router.navigate(this.config.refundNavigate.concat(['refund', amount, 'uf', userFrom, 'ut', userTo ]));
	}

	getUserName (idx) {
		return this.bill.users.find(user => {
			return user.id === idx;
		}).name;
	}

	swipe (way) {
		if (way === 'right' && this.activeTab > 0) {
			this.activeTab--;
		}

		if (way === 'left' && this.activeTab < 2) {
			this.activeTab++;
		}
	}

	setActiveTab (index) {
		this.activeTab = index;
	}

	getDebtorsTitle () {

		if (!this.bill.resolved) {
			return 'Внесено не всі розрахункові дані';
		}

		const resDebts = Object.keys(this.bill.debtors || { }).reduce((acc, name) => {
			return acc + Object.keys(this.bill.debtors[name].debtors).length;
		},                                                          0);

		return resDebts ? '' : 'Усі борги врегульовані';
	}

	getSubMenu (rights) {
		const menu = [];

		if (this.groupId) {

			// запис
			if (rights > 0) {
				menu.push({
					title: 'редагувати',
					href:  `/groups/${this.groupId}/payments/${this.paymentId}/edit`
				});
			}

			// власник
			if (rights > 1) {
				menu.push({
					title: 'видалити',
					href:  `/groups/${this.groupId}/payments/${this.paymentId}/delete`
				});
			}
		}

		if (!this.groupId && this.paymentId) {

			// запис
			if (rights > 0) {
				menu.push({
					title: 'редагувати',
					href:  `/payments/${this.paymentId}/edit`
				},        {
					title: 'поділитися',
					href:  `/payments/${this.paymentId}/share`
				});
			}

			// власник
			if (rights > 1) {
				menu.push({
					title: 'видалити',
					href:  `/payments/${this.paymentId}/delete`
				});
			}
		}

		return menu.length ? menu : null;
	}

	getTypeIcon (bill) {
		return bill.type === TYPES.BILL ? 'receipt' : 'payments';
	}
}
