<mat-card class="float-card text-center">
  @if (!showFinish) {
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-form-field class="first-row">
          <input matInput formControlName="email" name="email"
            type="email" placeholder="E-mail" maxlength="100">
        </mat-form-field>
        @if (form.get('email').invalid && form.get('email').touched) {
          <p class="title">
            Невірний формат E-mail
          </p>
        }
        <div class="button-row first-row">
          <button mat-raised-button [disabled]="form.invalid" type="submit" color="primary">
            Скинути пароль
          </button>
        </div>
      </form>
    </mat-card-content>
  } @else {
    <mat-card-content>
      <h2>Запит на відновлення пароля надіслано. Перевірте свою поштову скриньку</h2>
    </mat-card-content>
  }
  <mat-divider></mat-divider>
  <mat-card-actions class="block-center">
    <a mat-stroked-button color="primary" routerLink="/registration">
      Реєстрація
    </a>
    <a mat-stroked-button color="primary" routerLink="/login" color="primary">
      Вхід
    </a>
  </mat-card-actions>
</mat-card>
