import {Component, OnInit} from '@angular/core';

@Component({
	selector:    'app-unsubscribe',
	templateUrl: './unsubscribe.component.html',
	styleUrls:   ['./unsubscribe.component.css']
})
export class UnsubscribeComponent implements OnInit {

	constructor() {
	}

	ngOnInit() {
	}

}
