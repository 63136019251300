import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'qty'
})
export class QtyPipe implements PipeTransform {

	transform(value: number): string {
		const num = Math.round(value) / 100;
		if (num && num === num) {
			// todo maybe conflict with national standards
			return String(num).replace('.', ',');
		}

		return '0';
	}
}
