<div class="right-height">
	<mat-drawer-container class="right-height">
		<mat-drawer mode="over" #sidenav>
			<div class="account-wrapper">
				<div class="account">
					<div class="avatar">
						<mat-icon (click)="avatarClick()">account_circle</mat-icon>
					</div>
					<div>{{ user.name ? user.name : 'X-user' }}</div>
					<div class="flex">
						<span class="subtitle fixed-line">
							{{ user.email ? user.email : '' }}
						</span>
						<mat-menu #userMenu="matMenu">
							<a mat-menu-item routerLink="/user-info" (click)="sidenav.close()">
								Налаштування
							</a>
							<button mat-menu-item (click)="goLogout(sidenav)">
								Вийти
							</button>
						</mat-menu>
						<button class="right" mat-icon-button [matMenuTriggerFor]="userMenu">
							<mat-icon>more_vert</mat-icon>
						</button>
					</div>
				</div>
			</div>
			<a mat-button class="side-menu"
					(click)="sidenav.close()"
					routerLink="{{demoMode ? '/login' : '/bills/list'}}"
					routerLinkActive="active"
			>
				<mat-icon>list</mat-icon>
				<span>Список оплат</span>
			</a>
			<a mat-button class="side-menu"
			   (click)="sidenav.close()"
			   routerLink="{{demoMode ? '/login' : '/payments/create'}}">
				<mat-icon>payments</mat-icon>
				<span>Створити платіж</span>
			</a>
			<a mat-button class="side-menu" disabled >
				Групи
			</a>

			<a mat-button disableRipple="true" class="side-menu"
			   (click)="sidenav.close()"
			   routerLink="{{demoMode ? '/login' : '/groups/list'}}">
				<mat-icon>view_day</mat-icon>
				<span>Список груп</span>
			</a>
			<a mat-button disableRipple="true" class="side-menu"
			   (click)="sidenav.close()"
			   routerLink="{{demoMode ? '/login' : '/groups/create'}}">
				<mat-icon>create_new_folder</mat-icon>
				<span>Створити групу</span>
			</a>

			<div class="bottom-content">
				<a mat-button disableRipple="true" class="side-menu-bottom"
				   (click)="sidenav.close()"
				   routerLink="/">
					<mat-icon>info</mat-icon>
					<span>Про застосунок</span>
				</a>
			</div>
		</mat-drawer>

		<mat-toolbar color="primary">
			<mat-toolbar-row>
				<button mat-icon-button (click)="leftMenuClick(sidenav)">
					<mat-icon>{{leftMenu ? leftMenu.icon : 'menu'}}</mat-icon>
				</button>
				<div>{{metaInfo.title$ | async}}</div>
				<div [ngSwitch]="showType">
					<div *ngSwitchCase=1>
						<mat-menu #menu="matMenu">
							<button *ngFor="let it of rightMenu" mat-menu-item
									routerLink="{{it.href}}"
							>{{it.title}}</button>
						</mat-menu>
						<button mat-icon-button [matMenuTriggerFor]="menu">
							<mat-icon>more_vert</mat-icon>
						</button>
					</div>
					<div *ngSwitchCase=2>
						<a routerLink="{{rightMenu.href}}" mat-icon-button >
							<mat-icon>{{rightMenu.icon}}</mat-icon>
						</a>
					</div>
					<div *ngSwitchDefault>
					</div>
				</div>
				<ng-template #tmp>
					<div></div>
				</ng-template>
			</mat-toolbar-row>
		</mat-toolbar>
		<mat-progress-bar *ngIf="false" mode="indeterminate"></mat-progress-bar>
		<router-outlet></router-outlet>

		<app-preloader [diameter]="100" [show]="showPreloader"></app-preloader>

		<app-error-shower [error]="netError" [showError]="showError"></app-error-shower>

	</mat-drawer-container>
</div>
