import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../common/components/dialog/dialog.component';
import currencyJSON from '../../common/currency.json';
import { UserModelService } from '../../common/models/user-model.service';
import { HelperService } from '../../common/services/helper.service';
import { MetaInfoService } from '../../common/services/meta-info.service';
import { RequesterService } from '../../common/services/requester.service';

@Component({
	selector:    'app-group-create',
	templateUrl: './group-create.component.html',
	styleUrls:   ['./group-create.component.css']
})
export class GroupCreateComponent implements OnInit {
	@ViewChild('inputCur') input: ElementRef<HTMLInputElement>;

	// @ViewChild('nameInput', { static: true}) nameInput: ElementRef;

	message = '';
	name = '';
	stepNumber = 0;
	userName = '';
	user;
	fullCurList = currencyJSON.sort((a,b) => a.name < b.name ? -1 : 1);
	currencyList = this.fullCurList;

	groupObj: { name: string, currency: string , members: Array<{ id: number, name: string }> } = { name: '', currency: 'EUR', members: []};

	ROUTE = this.currentRout.snapshot.params.groupId ? 'updateGroup' : 'createGroup';

	constructor (
		private router: Router,
		private requester: RequesterService,
		private currentRout: ActivatedRoute,
		private metaInfo: MetaInfoService,
		private hs: HelperService,
		private userModel: UserModelService,
		private dialog: MatDialog,
	) {
	}

	groupId = this.currentRout.snapshot.params.groupId;

	async ngOnInit () {
		this.metaInfo.setData({
			title:     this.groupId ? 'Редагування групи' : 'Створення групи',
			rightMenu: {
				icon: 'cancel',
				href: this.groupId ? `/groups/${this.groupId}` : `/groups/list`
			},
		});

		if (this.groupId) {
			this.metaInfo.showPreloader(true);

			return this.requester.getGroup(this.groupId)
			.then(group => {

				this.groupObj.name = group.name;
				this.groupObj.members = group.members;
				this.groupObj.currency = group.currency;

				this.metaInfo.showPreloader(false);
			})
			.catch(err => {
				this.metaInfo.showError(err);
			});

		} else {

			const tmpData = window.localStorage.getItem('tmpGroup');
			if (tmpData) {
				const tmpGroupObj = JSON.parse(tmpData);

				this.groupObj.name = tmpGroupObj?.name || this.groupObj.name;
				this.groupObj.members = tmpGroupObj?.members || [];
			}
		}

		if (this.groupObj.members.length === 0) {
			const currentUser = await this.userModel.getUser();
			this.userName = currentUser.name;
		}

		// setTimeout(() => {
		// 	this.nameInput.nativeElement.select();
		// },         100);
	}

	async createGroup () {
		this.metaInfo.showPreloader(true);
		let currentGroupId;

		try {
			if (this.groupId) {
				currentGroupId = this.groupId;
				await this.requester[this.ROUTE](this.groupId, { name: this.groupObj.name, members: this.groupObj.members, currency: this.groupObj.currency });
			} else {
				window.localStorage.setItem('tmpGroup', JSON.stringify(this.groupObj));

				const group = await this.requester[this.ROUTE]({ name: this.groupObj.name, currency: this.groupObj.currency, members: this.groupObj.members});
				currentGroupId = group.num;

				window.localStorage.removeItem('tmpGroup');
			}

			window.localStorage.removeItem('tmpGroup');

			return this.router.navigate(['groups', currentGroupId]);

		} catch (e) {
			this.metaInfo.showError(e);
		}
	}

	tmpFieldsSave () {
		window.localStorage.setItem('tmpGroup', JSON.stringify(this.groupObj));
	}

	changeStep (e) {
		if (e.selectedIndex === 2) {
			// this.selectUser(-1);
		}
	}

	saveUser () {
		this.groupObj.members.push({
			id:   this.groupObj.members.length,
			name: this.userName || 'Учасник_' + (this.groupObj.members.length + 1)
		});

		this.userName = '';
		this.tmpFieldsSave();
	}

	checkGroupName () {
		if (!this.groupObj.name) {
			this.groupObj.name = 'Група ' + this.hs.getDateTime();
		}
	}

	editUser (id) {
		const dialogRef = this.dialog.open(DialogComponent, {
			width: '450px',
			data:  {
				showInput:     true,
				inputValue:    this.groupObj.members[id].name,
				title:         'Редагування участника',
				rightBtnTitle: 'Зберегти',
				leftBtnTitle:  this.groupId ? undefined : 'Видалити',
				leftBtnIcon:   'cancel'
			}
		});

		dialogRef.afterClosed().subscribe(async result => {

			switch (result && result.type) {
				case 'rightClick':
					this.groupObj.members[id].name = result.data.inputValue;
					this.tmpFieldsSave();
					break;

				case 'leftClick':
					await this.deleteUser(id);
					this.tmpFieldsSave();
					break;
			}
		});
	}

	async deleteUser (userId: number) {
		this.groupObj.members.splice(userId, 1);

		this.groupObj.members = this.groupObj.members.map((user, index) => {
			user.id = index;

			return user;
		});
	}

	filter () {
		this.currencyList = this.fullCurList;
		const filterValue = this.input.nativeElement.value.toLowerCase();

		if (filterValue) {
			this.currencyList = this.currencyList.filter((i => `${i.name} ${i.code}`.toLowerCase().includes(filterValue)));
		}
	}
}
