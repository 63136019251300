import { Component, DoCheck, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';

import { MetaInfoService } from '../../common/services/meta-info.service';
import { RequesterService } from '../../common/services/requester.service';

import { environment } from '../../../environments/environment';
import { AuthService } from '../../common/services/auth.service';

@Component({
	selector:    'app-bill-share',
	templateUrl: './bill-share.component.html',
	styleUrls:   ['./bill-share.component.css']
})
export class BillShareComponent implements OnInit {

	@ViewChild('tooltip', { static: true }) tooltip: MatTooltip;

	private paymentId = this.currentRout.snapshot.params.paymentId;
	private groupId = this.currentRout.snapshot.params.groupId;

	showTitle = true;

	link = '';
	collaborators;
	rights = 0;
	blockAdmin = false;
	checked = false;

	CONFIG = [{
		title:        'Поділитися платижем',
		methodShow:   'showBillShareLink',
		methodCreate: 'createBillShareLink',
		methodDelete: 'delBillShareLink',
		rightMenu:    {
			icon: 'cancel',
			href: `/payments/${this.paymentId}`
		},
		navigate:     [],
		params:       [this.paymentId],
		shareUrl:     'payment',
		changeRights: 'changePaymentRights',
	},        {
		title:        'Поділитись групою',
		methodShow:   'showGroupShareLink',
		methodCreate: 'createGroupShareLink',
		methodDelete: 'delGroupShareLink',
		rightMenu:    {
			icon: 'cancel',
			href: `/groups/${this.groupId}`
		},
		navigate:     [],
		params:       [this.groupId],
		shareUrl:     'group',
		changeRights: 'changeGroupRights',
	}];

	config = this.groupId ? this.CONFIG[1] : this.CONFIG[0];

	title = this.config.title;

	disabled;
	color;

	constructor (
		private auth: AuthService,
		private requester: RequesterService,
		private router: Router,
		private currentRout: ActivatedRoute,
		private metaInfo: MetaInfoService,

	) {
	}

	ngOnInit () {
		this.metaInfo.setData({
			rightMenu: this.config.rightMenu,
		});
		this.metaInfo.showPreloader(true);

		if (window.innerWidth < 430) {

			if (this.checkShare()) {
				this.showTitle = false;
			} else {
				if (window.innerWidth < 300) {
					this.showTitle = false;
				}
			}
		}

		this.requester[this.config.methodShow](...this.config.params)
		.then((data: any) => {

			this.metaInfo.showPreloader(false, this.config.title);
			if (data.link) {
				this.link = this.getUrl(data.link);
				this.checked = true;
			}

			this.rights = data.rights;

			this.collaborators = this.prepareCollaborators(data.collaborators || []);

		})
		.catch((err: Error) => {
			this.metaInfo.showError(err);
		});
	}

	prepareCollaborators (collaborators) {
		const collabs = collaborators.sort((a, b) => {
			return a.rights > b.rights ? -1 : 1;
		});
		const adminNum = collabs.filter(u => u.rights > 1).length;
		this.blockAdmin = adminNum < 2;

		return collabs;
	}

	toggleShare () {
		this.metaInfo.showPreloader(true);

		if (this.link) {
			this.requester[this.config.methodDelete](...this.config.params)
			.then((data: any) => {
				this.metaInfo.showPreloader(false, this.config.title);

				this.link = this.getUrl(data.link);
				this.checked = !!this.link;
			})
			.catch(err => {
				this.checked = !!this.link;
				this.metaInfo.showError(err);
			});
		} else {
			this.requester[this.config.methodCreate](...this.config.params)
			.then((data: any) => {
				this.metaInfo.showPreloader(false, this.config.title);

				this.link = this.getUrl(data.link);
				this.checked = !!this.link;
			})
			.catch(err => {
				// todo df change after finding
				// this.metaInfo.showError(err);

				this.metaInfo.showPreloader(false, this.config.title);
				if (err && err.code === 'NEED_AUTH') {
					this.auth.logout();

					return this.router.navigate(['login']);
				}

				this.checked = !!this.link;
			});
		}
	}

	getUrl (share) {
		if (share) {
			return `${environment.frontUrl}/share/${share}/${this.config.shareUrl}`;
		}

		return '';
	}

	copyToBuffer (el) {

		el.select();
		document.execCommand('copy');
		el.setSelectionRange(0, 0);
		this.tooltip.show();

		setTimeout(() => {
			this.tooltip.hide();
		},         2000);
	}

	shareLink () {
		const name = this.groupId ? 'группу' : (this.paymentId ? 'платіж' : 'рахунок');
		const text = `Посилання на ${name}`;

		navigator['share']({
			title: text,
			text,
			url:   this.link
		});
	}

	checkShare () {
		return !!(navigator && navigator['share']);
	}

	async changeRights (event, collab) {
		this.metaInfo.showPreloader(true);

		try {
			const res = await this.requester[this.config.changeRights](this.groupId || this.paymentId, collab.id, parseInt(event.value, 10));
			this.collaborators = this.prepareCollaborators(res.collaborators);

			if (res.rights < 1) {
				return  this.router.navigate([this.config.rightMenu.href]);
			}

		} catch (e) {
			//todo handle 404 with show error pop up
			if (e.status === 404) {
				return  this.router.navigate([this.config.rightMenu.href]);
			}

			throw e;
		}

		this.metaInfo.showPreloader(false);
	}
}
