import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from '../../common/services/helper.service';

import { MetaInfoService } from '../../common/services/meta-info.service';
import { RequesterService } from '../../common/services/requester.service';

@Component({
	selector:    'app-bills',
	templateUrl: './bills.component.html',
	styleUrls:   ['./bills.component.css'],
})
export class BillsComponent implements OnInit {

	billList = [];
	allData = [];

	constructor (
		private requester: RequesterService,
		private router: Router,
		private metaInfo: MetaInfoService,
		public  hs: HelperService,
	) {
		this.metaInfo.showPreloader(true);
	}

	async ngOnInit () {

		this.metaInfo.setData({
			rightMenu: [{
				icon:  'payment',
				title: 'Створити платіж',
				href:  `/payments/create`,
			}],
			title: 'Loading...',
		});

		return this.requester.getBills()
		.then(data => {

			this.metaInfo.showPreloader(false, 'Список оплат');

			this.billList = data;
			this.allData = data;
		})
		.catch(err => {
			this.metaInfo.showError(err);
		});
	}

	getTypeIcon (bill) {
		return bill.type === 'PAYMENT' ? 'payments' : 'receipt';
	}

	getStateIcon (bill) {
		if (bill.settled) {
			return 'done_outline';
		}

		if (bill.resolved) {
			return 'compare_arrows';
		}

		return 'edit';
	}

	changeList (value) {

		switch (value) {

			case 'debts':
				this.billList = this.allData.filter(item => {
					return item.resolved && !item.settled;
				});
				break;

			case 'drafts':
				this.billList = this.allData.filter(item => {
					return !item.resolved && !item.settled;
				});
				break;

			default:
				this.billList = this.allData;
				break;
		}
	}

	getLink (bill) {
		return 'payments';
	}
}
