<mat-card class="float-card">
  <mat-card-content>
    <mat-vertical-stepper #stepper [selectedIndex]="stepNumber" (selectionChange)="changeStep($event)">
      <mat-step [hasError]="!groupObj.name">
        <ng-template matStepLabel>{{ groupObj.name }}</ng-template>
        <form class="">
          <div class="field-keeper">
            <mat-form-field class="">
              <input matInput name="name" placeholder="Назва групи"
                [(ngModel)]="groupObj.name" (blur)="tmpFieldsSave()">
            </mat-form-field>
          </div>
        </form>
        <form class="example-form">
          <mat-form-field>
            <mat-label>Валюта</mat-label>
            <input #inputCur
              type="text"
              placeholder="Pick one"
              matInput
              name="currency"
              [(ngModel)]="groupObj.currency"
              [matAutocomplete]="auto"
              (input)="filter()"
              (focus)="filter()">
            <mat-autocomplete requireSelection #auto="matAutocomplete" panelWidth="300px" class="text-left">
              @for (option of currencyList; track option) {
                <mat-option style="width: 400px" [value]="option.code">{{option.country}} {{option.name}}: {{option.code}}</mat-option>
              }
            </mat-autocomplete>
          </mat-form-field>
        </form>
        <mat-divider></mat-divider>
        <div class="first-row button-row">
          <button mat-raised-button color="accent" (click)="checkGroupName()" matStepperNext>Далі</button>
        </div>
      </mat-step>
      <mat-step
        >
        <ng-template matStepLabel>Учасники</ng-template>
        <div>
          <div style="position: relative;">
            <mat-list role="list">
              @for (user of groupObj.members; track user; let i = $index) {
                <mat-list-item role="listitem">
                  <div matListItemTitle>{{ i + 1 }}. {{ user.name }}</div>
                  <button matListItemMeta mat-icon-button (click)="editUser(i)">
                    <mat-icon class="mat-24">mode_edit</mat-icon>
                  </button>
                  <mat-divider></mat-divider>
                </mat-list-item>
              }
            </mat-list>
          </div>
          <div class="first-row button-row">
            <mat-form-field appearance="outline">
              <mat-label>Введіть ім'я</mat-label>
              <input matInput #userInput [(ngModel)]="userName">
              <button type="button" mat-icon-button matSuffix color="primary" (click)="saveUser()">
                <mat-icon>add</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="first-row button-row">
          <button mat-raised-button color="accent" matStepperPrevious>Назад</button>
          <button type="button" mat-raised-button color="primary" (click)="createGroup()">
            Зберегти групу
          </button>
        </div>
      </mat-step>
    </mat-vertical-stepper>
  </mat-card-content>
</mat-card>
