@if (!!group) {
  <mat-card class="float-card text-center">
    <mat-card-content>
      <div class="top-holder">
        <div class="date">
          <mat-icon>event</mat-icon>
          <span>{{ hs.formatDateTime(group.date) }}</span>
        </div>
        <div class="currency">
          <span>{{ group.currency }}</span>
        </div>
      </div>
      <mat-card-title>
        <mat-icon class="inline-icon" matListItemIcon>folder_open</mat-icon>
        {{ group.name }}
      </mat-card-title>
      <mat-card-subtitle>
        Всього витрачено: {{ group.allSum | money }}
      </mat-card-subtitle>
      <mat-card-subtitle>
        Всього платежів: {{ group.billCount }}
      </mat-card-subtitle>
    </mat-card-content>
  </mat-card>
}
@if (!!group && bills && bills.length) {
  <mat-card class="float-card text-center">
    <mat-card-content class="text-center">
      @if (debtorsList.length) {
        <mat-card-title>
          <mat-icon title="" class="inline-icon">compare_arrows</mat-icon>
          <span>Борги</span>
        </mat-card-title>
      }
      @if (!debtorsList.length) {
        <mat-card-title>
          <mat-icon title="">done_all</mat-icon>
          <span>Борги врегульовані</span>
        </mat-card-title>
      }
    </mat-card-content>
    <mat-card-content>
      @if (rights > 0) {
        <mat-selection-list>
          @for (debtObj of debtorsList; track debtObj; let isLast = $last) {
            <mat-list-item
              class="border debt-list"
              (click)="refund(debtObj.debtorId, debtObj.userId, debtObj.sum)">
              <div matListItemTitle class="list-inner-table">
                <span class="list-inner-table-first-cell">{{ debtObj.debtorName }}</span>
                <span>
                  <span>{{ debtObj.sum | money }}</span>
                  <mat-icon class="inline-icon">arrow_forward</mat-icon>
                </span>
                <span class="list-inner-table-last-cell">{{ debtObj.userName }}</span>
              </div>
            </mat-list-item>
          }
        </mat-selection-list>
      } @else {
        <mat-list>
          @for (debtObj of debtorsList; track debtObj; let isLast = $last) {
            <mat-list-item
              class="border debt-list">
              <div matListItemTitle class="list-inner-table">
                <span class="list-inner-table-first-cell">{{ debtObj.debtorName }}</span>
                <span>
                  <span>{{ debtObj.sum | money }}</span>
                  <mat-icon class="inline-icon">arrow_forward</mat-icon>
                </span>
                <span class="list-inner-table-first-cell">{{ debtObj.userName }}</span>
              </div>
            </mat-list-item>
          }
        </mat-list>
      }
    </mat-card-content>
  </mat-card>
}
<mat-card class="float-card text-left">
  <mat-card-content>
    @if (bills && bills.length) {
      <mat-selection-list id="payments">
        @for (item of bills; track item) {
          <mat-list-item class="pointer border list-slim "
            [ngClass]="item.resolved ? '' : 'app-warning'"
            [routerLink]="getBillUrl(item)">
            <mat-icon class="list-icon" matListItemIcon>{{ getTypeIcon(item) }}</mat-icon>
            <mat-icon class="list-icon" matListItemIcon>{{ getStateIcon(item) }}</mat-icon>
            <h3 matListItemTitle>{{ item.name }}</h3>
            <p matListItemLine class="text-center">
              <!--<mat-chip-list>-->
              <!--<mat-chip>O</mat-chip>-->
              <!--<mat-chip>T</mat-chip>-->
              <!--<mat-chip color="primary" selected="true">P</mat-chip>-->
              <!--<mat-chip color="accent" selected="true">A</mat-chip>-->
            <!--</mat-chip-list>-->
            <span style="">{{ hs.formatDateTime(item.date) }}</span>
          </p>
          <p matListItemMeta>
            <span class="g-p-sum">{{ item.allSum | money }}</span>
          </p>
          <!--<p matLine> кто-то забашляв </p>-->
        </mat-list-item>
      }
    </mat-selection-list>
  } @else {
    <mat-card-header>
      <mat-card-title>Ця група поки порожння</mat-card-title>
    </mat-card-header>
  }
</mat-card-content>
</mat-card>
@if (rights > 0) {
  <button class="fixed-action-btn" routerLink="/groups/{{group?.id}}/payments/create" mat-fab color="primary"
    aria-label="Додати оплату">
    <mat-icon>add</mat-icon>
  </button>
}
