import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	// {path: '', redirectTo: '/bills/create', pathMatch: 'full'},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { })],
	exports: [RouterModule],
})
export class AppRoutingModule {
}
