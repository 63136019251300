import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'money'
})
export class MoneyPipe implements PipeTransform {

	transform(value: any, showZero: boolean = true): string {

		let num = Math.round(value) / 100;
		num = num === num ? num : 0;

		if (!showZero && !num) {
			return '';
		}

		let str =  String(num).replace('.', ',');
		const index = str.indexOf(',');

		if (index === -1) {
			str += ',00';

		} else if (str.length - index === 2) {
			str += '0';
		}

		return str;
	}
}
