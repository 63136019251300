<mat-card class="float-card">
	<mat-card-content>
		<mat-card-title>Видалити групу?</mat-card-title>
		<mat-slide-toggle
			class="first-row"
			(click)="toggleDelete()"
			[color]="color"
			[checked]="checked"
			[disabled]="disabled">
			Підтвердити?
		</mat-slide-toggle>

	</mat-card-content>

	<mat-card-content>
		<div class="both-button-row first-row">
			<button type="button" mat-raised-button color="primary" [disabled]="disabledButton" (click)="deleteGroup()">
				Видалити групу
			</button>
		</div>
	</mat-card-content>

</mat-card>
