import { Injectable } from '@angular/core';

/**
 * helper functions
 */
@Injectable()
export class HelperService {
	getDateTime<String> (date?) {
		if (!date) {
			date = new Date();
		} else {
			date = new Date(date);
		}

		return date.getFullYear() + '-' + this.addZero(date.getMonth() + 1)
			+ '-' + this.addZero(date.getDate()) + 'T' + this.addZero(date.getHours()) + ':' + this.addZero(date.getMinutes());
	}

	addZero (num) {
		if (num < 10) {
			return '0' + num;
		}

		return String(num);
	}

	formatDateTime (dateStr: string) {
		// todo df fixe date utc
		// const date = new Date(dateStr+'.000Z');
		const date = new Date(dateStr);

		return `${date.getFullYear()}-${this.addZero(date.getMonth() + 1)}-${this.addZero(date.getDate())} `
			+ `${this.addZero(date.getHours())}:${this.addZero(date.getMinutes())}`;
	}
}
