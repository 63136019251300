@if (form) {
  <mat-card class="float-card text-center">
    <mat-card-title>
      Вхід
    </mat-card-title>
    <mat-card-content>
      <div class="text-left">
        <mat-divider></mat-divider>
        авторизуйтесь
      </div>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div>
          <mat-form-field class="first-row">
            <input matInput formControlName="email" name="email"
              type="email" placeholder="E-mail" maxlength="100">
          </mat-form-field>
          @if (form.get('email').invalid && form.get('email').touched) {
            <p class="title">
              Неправильний формат E-mail
            </p>
          }
        </div>
        <div>
          <mat-form-field class="example-full-width">
            <input matInput formControlName="password" type="password"
              name="password" placeholder="Password" maxlength="100">
          </mat-form-field>
          @if (form.get('password').invalid && form.get('password').touched) {
            <p class="title">
              Поле не повинно бути менше 6-ти символів
            </p>
          }
        </div>
        <section class="button-row first-row">
          <mat-checkbox formControlName="extra">Запамʼятати мене</mat-checkbox>
        </section>
        <div class="button-row first-row">
          <button mat-raised-button [disabled]="form.invalid || disableButton"
            type="submit" color="primary">Вхід
          </button>
        </div>
      </form>
      @if (showSocialLogin) {
        <div class="first-row">
          <div class="text-left">
            <mat-divider></mat-divider>
            чи увійдіть за допомогою соц. мереж
          </div>
          <app-preloader [global]="false" [diameter]="50" [show]="showSpinner"></app-preloader>
          <iframe #iframe id="socialLogin" name="socialLogin" [src]="frameSRC"></iframe>
        </div>
      }
    </mat-card-content>
    <mat-divider></mat-divider>
    <mat-card-actions class="block-center">
      <a mat-stroked-button color="primary" routerLink="/reset-pass">
        Зкидання паролю
      </a>
      <a mat-stroked-button color="primary" routerLink="/registration">
        Реєстрація
      </a>
    </mat-card-actions>
  </mat-card>
}
