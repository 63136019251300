import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import {RequesterService} from '../../common/services/requester.service';
import {MetaInfoService} from '../../common/services/meta-info.service';
import {DialogComponent} from '../../common/components/dialog/dialog.component';
import { AuthService } from '../../common/services/auth.service';
import { StorageService } from '../../common/services/storage.service';

interface Member {
	id: number;
	name: string;
}

@Component({
	selector:    'app-group-members',
	templateUrl: './group-members.component.html',
	styleUrls:   ['./group-members.component.css']
})
export class GroupMembersComponent implements OnInit {

	@ViewChild('input', { static: true }) input: ElementRef;

	private title = 'Добавление участников';
	message = '';

	groupObj: { name?: string, members: Member[] } = {
		members: []
	};
	usersList = [];
	formName = '';
	private editContact;


	constructor(
		private auth: AuthService,
		private router: Router,
		private currentRout: ActivatedRoute,
		private requester: RequesterService,
		private metaInfo: MetaInfoService,
		public  dialog: MatDialog,
		private storage: StorageService
	) {
	}

	async ngOnInit() {

		this.metaInfo.setData({
			title:     this.title,
			rightMenu: {
				icon: 'cancel',
				href: `/groups/list`
			},
		});

		const tmpData = await this.storage.get('tmpGroupMembers');

		// console.log('=-=-temp group0', tmpData);


		if (tmpData) {
			this.groupObj.members = tmpData || [];
			this.usersList = tmpData || [];
		}

		if (!this.groupObj.members.length) {
			const user = await this.storage.get('user');
			this.formName = this.generateUserName(user && user.name);
		} else {
			this.formName = this.generateUserName();
		}

		setTimeout(() => {
			this.input.nativeElement.select();
		}, 100);
	}

	editUser(id) {

		const user = this.usersList.find(c => {
			return c.id === id;
		});

		const dialogRef = this.dialog.open(DialogComponent, {
			width: '450px',
			data:  {
				userId:        user.id,
				showInput:     true,
				inputValue:    user.name,
				title:         'Редагування',
				rightBtnTitle: 'Зберегти',
				leftBtnTitle:  'Видалити',
				leftBtnIcon:   'delete',
				leftBtnColor:  'warn',
			}
		});

		dialogRef.afterClosed().subscribe(result => {

			switch (result && result.type) {
				case 'rightClick':
					this.usersList.find((c) => {
						return c.id === result.data.userId;
					}).name = result.data.inputValue;
					break;

				case 'leftClick':
					this.deleteUser(result.data.userId);
					break;
			}

			this.groupObj.members = this.usersList;
			this.storage.set('tmpGroupMembers', this.groupObj.members);
		});
	}

	deleteUser(userId) {
		this.usersList = this.usersList.filter((item) => {
			return item.id !== userId;
		}).map((item, index) => {
			item.id = index;
			return item;
		});
	}

	addUser() {
		if (this.editContact != null) {
			this.usersList.find((c) => {
				return c.id === this.editContact;
			}).name = this.formName;
			this.editContact = null;

		} else {
			this.usersList.push({id: this.usersList.length, name: this.formName});
		}

		this.groupObj.members = this.usersList;
		this.formName = this.generateUserName();

		this.storage.set('tmpGroupMembers', this.groupObj.members);

		setTimeout(() => {
			this.input.nativeElement.select();
		}, 100);
	}

	generateUserName (name?) {
		return name ? name : 'участник_' + (this.usersList.length + 1);
	}

	saveUsers() {

		this.requester.createGroupMembers(this.currentRout.snapshot.params.id, this.groupObj)
		.then((res) => {

			this.storage.del('tmpGroupMembers');
			this.router.navigate(['groups', this.currentRout.snapshot.params.id]);
		})
		.catch(err => {
			this.metaInfo.showError(err);
		});
	}
}
