import { Component, OnInit } from '@angular/core';

import {
	FacebookLoginProvider,
	GoogleLoginProvider,
	SocialAuthService as ExternalAuth,
	SocialUser,
} from '@abacritt/angularx-social-login';

import { AuthService } from '../common/services/auth.service';
import { PostMessageService } from '../common/services/post-message.service';
import { RequesterService } from '../common/services/requester.service';

@Component({
	selector:    'app-social-login',
	templateUrl: './social-login.component.html',
	styleUrls:   ['./social-login.component.css'],
})
export class SocialLoginComponent implements OnInit {

	private user: SocialUser;
	private loggedIn: boolean;

	constructor (
		private auth: AuthService,
		private requester: RequesterService,
		private postMessage: PostMessageService,
		private externalAuth: ExternalAuth,
	) {
	}

	ngOnInit () {

		this.externalAuth.authState.subscribe(user => {
			this.backLogin(user);
		});

		this.externalAuth.authState.subscribe(user => {
			// todo df should unregister user when it exits, bc we receive used data from last time
			console.log('=-=-=-= change state: user is', !!user);

			// this.user = user;
			// this.loggedIn = (user != null);
		});
	}

	signInWithFB (): void {
		this.signIn(FacebookLoginProvider.PROVIDER_ID);
	}

	signIn (provider) {

		// !start preloader
		this.postMessage.send('preloader', true);

		this.externalAuth.signIn(provider)
		.then(this.backLogin)
		.catch(err => {
			console.log('=--==-err', err);

			this.postMessage.send('preloader', false);
		});
	}

	async backLogin (data) {
		// data = {
		// 	authToken: 'EAAeZBVy8mUug2RC45lFZAe5geQNu5a2UROXE9CLycOfHQWBx6TTaXUk3',
		// 	email: 'juriy_prof@ukr.net',
		// 	facebook: {
		// 		email: 'juriy_prof@ukr.net',
		// 		first_name: 'Yuriy',
		// 		id: '1066724',
		// 		last_name: 'Ivanov',
		// 		name: 'Yuriy  Ivanov',
		// 		picture: {
		// 			data: {
		// 				height: 50,
		// 				is_silhouette: false,
		// 				url: 'https://platform-lookaside.fbsbx.cI',
		// 				width: 50
		// 			}
		// 		},
		// 	},
		// 	firstName: 'Yuriy',
		// 	id: '10724',
		// 	lastName: 'Ivanov',
		// 	name: 'Yuriy  Ivanov',
		// 	photoUrl: 'https://graph.facebook.com/1066484326877724/picture?type=normal',
		// 	provider: 'FACEBOOK'
		// };

		// data = {
		// 	email: 'hob@gmail.com',
		// 	firstName: 'hab',
		// 	id: '1183124',
		// 	idToken: 'eyJhbGciOiJSUzI1NKrwVNYM5JNg',
		// 	lastName: undefined,
		// 	name: 'hab',
		// 	photoUrl: 'https://lh3.googleusercontent.com/a/AGNmyxbo0lkCLUzDr5BbAw4wfVa7lGutyc_EW5oXehh6=s96-c',
		// 	provider: 'GOOGLE',
		// };

		this.postMessage.send('preloader', true);

		let photoUr;

		switch (data.provider) {
			case 'FACEBOOK':
				photoUr = data?.facebook?.picture?.data?.url || data.photoUrl;
				break;
			case 'GOOGLE':
				photoUr = data.photoUrl;
				break;
			default:
				photoUr = data.photoUrl;
		}

		const sendData = {
			token:    data.idToken || data.authToken,
			email:    data.email,
			name:     data.name || `${data.firstName || ''} ${data.lastName || ''}`.trim() || 'unknown',
			photoUr,
			provider: data.provider,
		};

		const response = await this.requester.loginWithSocial(sendData);
		this.postMessage.send('tokens', response);
		this.postMessage.send('preloader', false);
	}
}
