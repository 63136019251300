import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { BillService, TYPES } from '../../common/services/bill.service';
import { HelperService } from '../../common/services/helper.service';
import { RequesterService } from '../../common/services/requester.service';

import { MatTableDataSource } from '@angular/material/table';
import { DialogComponent } from '../../common/components/dialog/dialog.component';
import { AuthService } from '../../common/services/auth.service';
import { MetaInfoService } from '../../common/services/meta-info.service';
import { StorageService } from '../../common/services/storage.service';
import { FIELD_OF_CHECK } from '../check-create/check-create.component';

enum mode {
	SINGLE,
	SINGLE_EDIT,
	GROUP,
	GROUP_EDIT,
}

export interface Element {
	id: number;
	name: string;
	cost: number;
	quantity: number;
	sumBD: number;
	disSum: number;
	disPercent: number;
	gSum: number;
	resolve?: string;  // поле которое было высчетанно автоматически
}

export interface Row {
	name: string;
	cost: number;
	quantity: number;
	gSum: number;
	empty: string;
	users: [];
}

export class Payment {
	id ?: number;
	name: string;
	sum: number;
	tip ?: number;
	allSum ?: number;
	fill ?: number;
	rows ?: Array<Row>;
	type ?: TYPES;
	date: string;
	timeStamp: string;
	desc: string;

	constructor (type: TYPES = TYPES.PAYMENT) {
		this.name = '';
		this.type = type;
	}
}

@Component({
	selector:    'app-payment-create',
	templateUrl: './payment-create.component.html',
	styleUrls:   ['./payment-create.component.css']
})
export class PaymentCreateComponent implements OnInit {

	constructor (
		private auth: AuthService,
		private router: Router,
		private currentRout: ActivatedRoute,
		public  bs: BillService,
		private requester: RequesterService,
		public  dialog: MatDialog,
		public  metaInfo: MetaInfoService,
		public  storage: StorageService,
		public  hs: HelperService,
	) {

		this.ids.group = this.currentRout.snapshot.params.groupId;
		this.ids.payment = this.currentRout.snapshot.params.paymentId;

		this.submenu = {
			icon: 'cancel',
		};

		switch (true) {
			case !!this.ids.group && !!this.ids.payment:
				this.submenu.href = `/groups/${this.ids.group}/payments/${this.ids.payment}`;
				break;

			case !!this.ids.group:
				this.submenu.href = `/groups/${this.ids.group}`;
				break;

			case !!this.ids.payment:
				this.submenu.href = `/payments/${this.ids.payment}`;
				break;

			default:
				this.submenu.href = `/bills/list`;
		}

		this.CONFIG = [{
			localStorageName: 'tmpPayment',
			getData:          'getFullBill',
			params:           [],
			createPayment:    'createPayment',
			navigate:         ['payments'],
			title:            'Створення платежу'
		},             {
			localStorageName: 'tmpPayment',
			getData:          'getFullBill',
			params:           [this.ids.payment],
			createPayment:    'editPayment',
			navigate:         ['payments'],
			title:            'Редагування платежу'
		},             {
			localStorageName: 'tmpGroupPayment',
			getData:          'getGroupMembers',
			params:           [this.ids.group],
			createPayment:    'createGroupPayment',
			navigate:         ['groups', this.ids.group, 'payments'],
			title:            'Створення платежу'
		},             {
			localStorageName: 'tmpGroupPayment',
			getData:          'getGroupBillFull',
			params:           [this.ids.group, this.ids.payment],
			createPayment:    'editGroupPayment',
			navigate:         ['groups', this.ids.group, 'payments'],
			title:            'Редагування платежу'
		}];

		this.creationMode = this.ids.group
			? (this.ids.payment ? mode.GROUP_EDIT : mode.GROUP)
			: (this.ids.payment ? mode.SINGLE_EDIT : mode.SINGLE);
		this.curConfig = this.CONFIG[this.creationMode];

		let suffix = '';
		suffix += this.ids.group ? `-group-${this.ids.group}` : '';
		suffix += this.ids.payment ? `-payment-${this.ids.payment}` : '';

		this.keys = {
			local:    `${this.curConfig.localStorageName}${suffix}`,
			users:    `${this.curConfig.localStorageName}-users${suffix}`,
			payments: `${this.curConfig.localStorageName}-payments${suffix}`,
			rates:    `${this.curConfig.localStorageName}-userRate${suffix}`,
			rows:     `${this.curConfig.localStorageName}-rows${suffix}`,
		};
	}

	userList = [];

	protected readonly ids: { payment: number, group: number } = { payment: null, group: null };
	private keys: { local: string, users: string, payments: string, rates: string, rows: string };

	private timeStamp = this.hs.getDateTime();
	modes = mode;
	creationMode;
	curConfig;

	userActive = false;
	payActive  = false;
	separationActive = false;

	private rawPayments;

	private payments = [];
	payment = new Payment();
	name: string;
	sum: number = null;
	date;
	desc;
	userRate = [];

	tip = null;
	allSum = null;
	allSumSlave = 0;
	rowsSum = 0;
	currentPay = 0;
	showAllPay = 0;
	paidUsers = '';
	tmpSeparatedSums = [];

	userCheckMode = 1;
	userColor = 'primary';
	stepNumber = 0;
	removeCheckedActive: boolean = null;

	private title = 'Створення платежу';
	private submenu;

	userName = '';

	// для установки фокуса на инпут
	@ViewChild('activeInput', { static: true })
	private _inputElement: ElementRef;

	@ViewChild('userInput')
	private userInput: ElementRef;

	activeTab = 0;

	private CONFIG;

	protected readonly TYPES = TYPES;

	types = [
		{ id: TYPES.PAYMENT, name: 'Платіж'},
		{ id: TYPES.BILL, name: 'Рахунок'},
	];

	operationType = TYPES.PAYMENT;

	rows = [];
	displayedColumns = ['position', 'name', 'cost', 'quantity', 'gSum'];
	defDisplayedColumns = ['position', 'name', 'cost', 'quantity', 'gSum'];
	dataSource = new MatTableDataSource<Element>(this.rows);
	row: any = {
		name:     '',
		cost:     null,
		quantity: null,
		gSum:     null,
		empty:    '',
		users:    [],
	};

	private localKey = 'details-';
	canSave = true;
	addingRow = false;

	async activeInput (el) {
		// await el.nativeElement.focus();
	}

	async ngOnInit () {
		if (this.ids.group || this.ids.payment) {
			this.title = this.curConfig.title;
			this.metaInfo.showPreloader(true);
			try { // получение данных с сервера
				const res = await this.requester[this.curConfig.getData](...this.curConfig.params);
				this.metaInfo.showPreloader(false);

				if (this.ids.payment) {

					await this.storage.set(this.keys.local, res);
					await this.storage.set(this.keys.payments, this.bs.convertPaymentsToForm(res.payments));
					await this.storage.set(this.keys.users, res.users || res.members);
					await this.storage.set(this.keys.rates, res.users.map(i => 1));
					await this.storage.set(this.keys.rows, this.bs.convertRowsToFront(res.rows || []));
				} else {
					const userRate = (res.users || res.members).map(i => 1);
					await this.storage.set(this.keys.rates, userRate);
					await this.storage.set(this.keys.users, res.members);
				}
			} catch (err) {
				this.metaInfo.showError(err);
			}
		}

		try {
			this.metaInfo.setData({
				rightMenu: this.submenu,
				title:     this.title
			});

			this.payment = await this.storage.get(this.keys.local) || new Payment();
			if (this.payment.date) {
				this.date = this.payment.date;
			} else {
				this.date = this.hs.getDateTime();
			}

			this.sum = this.bs.decodeMoney(this.payment.sum);
			if (this.payment.fill) {
				this.tip = this.bs.decodeMoney(this.payment.tip);
			} else {
				this.allSum = this.bs.decodeMoney(this.payment.allSum);
			}

			this.userList = await this.storage.get(this.keys.users) || [];
			this.payments = await this.storage.get(this.keys.payments) || [];
			this.userRate = await this.storage.get(this.keys.rates) || [];
			this.rows     = await this.storage.get(this.keys.rows) || [];
			this.userCheckMode = this.userRate.find(u => u > 1) ? 2 : 1;
			this.operationType = this.payment.type == null ? this.operationType : this.payment.type;

			this.displayedColumns = this.defDisplayedColumns.concat(this.getUserColumn(this.userList));

			this.renewCurrentPayment();

			this.dataSource = new MatTableDataSource<Element>(this.rows);
			await this.activeInput(this._inputElement);

		} catch (err) {
			this.metaInfo.showError(err);
		}
	}

	async saveUser () {

		this.userList.push({
			id:   this.userList.length,
			name: this.userName || `Учасник_${this.userList.length + 1}`
		});

		this.userRate.push(1);
		await this.storage.set(this.keys.rates, this.userRate);

		this.userName = '';

		await this.activeInput(this.userInput);

		await this.storage.set(this.keys.users, this.userList);

		this.rows = this.rows.map(row => {
			row.users.push(null);

			return row;
		});

		this.row.users.push(1);

		this.displayedColumns = this.defDisplayedColumns.concat(this.getUserColumn(this.userList));
	}

	updateUser () {
		window.localStorage.setItem(
			this.keys.users,
			JSON.stringify(this.userList)
		);
	}

	writingUsers (users = []) {
		return users.reduce((acc, s) => (+s || 0) + acc, 0);
	}

	async createPayment () {
		this.metaInfo.showPreloader(true);

		this.payment.sum = this.bs.convert(this.sum);
		this.payment.date = this.date;
		this.payment.type = this.operationType;
		// this.payment.fill = this.fill;

		const data = {
			...this.payment,
			payments: this.payments.map(userPaument => {
				return this.bs.convert(userPaument);
			}),
			users:     this.userList,
			userRates: undefined,
			rows:      undefined,
		};

		if (this.operationType === 0) {
			data.userRates = this.userRate.map(rates => {
				return this.bs.convert(rates);
			});
		} else {
			data.rows = this.bs.convertRowsToBack(this.rows);
		}

		try {
			const res = await this.requester[this.curConfig.createPayment](...[...this.curConfig.params, data]);
			await this.metaInfo.showPreloader(false);

			await this.cleanTmpStorage();

			await this.router.navigate(this.curConfig.navigate.concat(res.id));
		} catch (err) {
			this.metaInfo.showError(err);
		}
	}

	renewCurrentPayment () {
		this.rawPayments = this.payments.map(payment => {
			return this.bs.convert(payment);
		});

		this.paidUsers = this.payments.map((p, idx) => {
			return p ? this.userList[idx].name : '';
		}).filter(userName => !!userName).join(', ');

		this.currentPay = this.payment.allSum - this.rawPayments.reduce((acc, payment) => {
			return acc + payment;
		},                                                              0);
	}

	// setActiveTab (index) {
	// 	this.activeTab = index;
	// }

	swipe (way) {
		if (way === 'right' && this.activeTab > 0) {
			this.activeTab--;
		}

		if (way === 'left' && this.activeTab < 3) {
			this.activeTab++;
		}
	}

	editUser (id) {

		const dialogRef = this.dialog.open(DialogComponent, {
			width: '450px',
			data:  {
				showInput:     true,
				inputValue:    this.userList[id].name,
				title:         'Редагування участника',
				rightBtnTitle: 'Зберегти',
				leftBtnTitle:  'Видалити',
				leftBtnIcon:   'cancel'
			}
		});

		dialogRef.afterClosed().subscribe(async result => {

			switch (result && result.type) {
				case 'rightClick':
					this.userList[id].name = result.data.inputValue;
					this.updateUser();
					break;

				case 'leftClick':
					await this.deleteUser(id);
					this.updateUser();
					break;
			}

			this.displayedColumns = this.defDisplayedColumns.concat(this.getUserColumn(this.userList));
		});
	}

	async deleteUser (userId: number) {
		this.userList.splice(userId, 1);
		this.userRate.splice(userId, 1);
		this.payments.splice(userId, 1);

		await this.storage.set(this.keys.rates, this.userRate);
		await this.storage.set(this.keys.payments, this.payments);

		this.userList = this.userList.map((user, index) => {
			user.id = index;

			return user;
		});

		await this.storage.set(this.keys.users, this.userList);

		this.renewCurrentPayment();

		this.rows = this.rows.map(row => {
			row.users.splice(userId, 1);

			return row;
		});

		this.row.users.splice(userId, 1);

		await this.storage.set(this.keys.rates, this.userRate);

		this.displayedColumns = this.defDisplayedColumns.concat(this.getUserColumn(this.userList));
	}

	async selectUser (userId?: number, way?: boolean) {
		switch (this.userCheckMode) {
			case 1: {
				this.userRate = this.userList.map((user, index) => {
					if (index === userId) {
						return this.userRate[index] ? 0 : 1;
					}

					return this.userRate[index] ? 1 : 0;
				});

				break;
			}
			case 2: {
				if (way) {
					this.userRate = this.userRate.map((part, index) => {
						if (index === userId) {
							return part + 1;
						}

						return part;
					});
				} else {
					this.userRate = this.userRate.map((part, index) => {
						if (index === userId) {
							return part - 1;
						}

						return part;
					});
				}
				break;
			}
			default:
		}

		await this.storage.set(this.keys.rates, this.userRate);

		this.tmpUserPayments();
	}

	tmpUserPayments () {
		let preparedSeparatedSum;
		let realSeparatedSum;

		const numUsedUsers = this.userRate.reduce((acc, rate) => {
			return rate ? (acc + rate) : acc;
		},                                      0);

		let prefix = '';
		if (numUsedUsers) {

			if (this.operationType === 0) {
				realSeparatedSum = this.payment.allSum / numUsedUsers;
			} else if (this.operationType === 1) {
				realSeparatedSum = (this.row.gSum && this.bs.convert(this.row.gSum) || this.bs.cGetGSum(this.row.cost, this.row.quantity)) / numUsedUsers;
			}

			if ((String(realSeparatedSum).split('.')?.[1] || '').length > 2) {
				prefix = '~';
				preparedSeparatedSum = Math.round(realSeparatedSum);
			} else {
				preparedSeparatedSum = realSeparatedSum;
			}
		} else {
			preparedSeparatedSum = 0;
		}

		this.tmpSeparatedSums = this.userRate.map(rate => rate ? prefix + Math.round(rate * preparedSeparatedSum) / 100 : 0);

	}

	getUserColumn (userList) {
		return userList.map(u => 'u' + u.id);
	}

	checkActiveButton (userId: number) {
		return !(this.userRate[userId] > 0);
	}

	async changeMode (checkMode: number) {
		this.removeCheckedActive = null;

		this.userCheckMode = checkMode;

		if (checkMode === 1) {
			this.userRate = this.userRate.map((item: number) => {
				return item ? 1 : 0;
			});
			await this.storage.set(this.keys.rates, this.userRate);
		}

		this.selectUser();
	}

	async removeChecked () {
		if (this.userCheckMode === 1) {
			this.userColor = 'primary';
		}

		this.userRate = this.userList.map(() => {
			return 0;
		});

		await this.selectUser(-1);
		await this.storage.set(this.keys.rates, this.userRate);
	}

	async checkAll () {
		if (this.userCheckMode === 1) {
			this.userColor = 'primary';
		}

		this.userRate = this.userList.map(() => {
			return 1;
		});

		await this.selectUser(-1);
		await this.storage.set(this.keys.rates, this.userRate);
	}

	async increaseUser (userId: number) {

	}

	async decreaseUser (userId: number) {

	}

	async savePayments () {
		this.selectUser(-1);
		await this.storage.set(this.keys.payments, this.payments);
	}

	tmpFieldsSave () {
		const data = {
			name:   this.payment.name,
			sum:    this.sum,
			tip:    this.tip,
			allSum: this.allSum,
			date:   this.date,
			desc:   this.payment.desc,
			type:   this.operationType,
		};

		const convertData = this.bs.convertBill(data);

		this.payment.sum = this.bs.convert(data.sum);
		if (data.tip) {
			this.payment.tip = this.bs.convert(data.tip);
		} else if (data.allSum) {
			this.payment.tip = this.bs.cGetTips(data.allSum, data.sum);
		}

		this.payment.allSum = data.allSum ? this.bs.convert(data.allSum) : this.payment.allSum = this.bs.cGetAllSum(data.sum, data.tip);
		this.payment.date = data.date;
		this.payment.fill = convertData.fill;

		this.renewCurrentPayment();

		window.localStorage.setItem(this.keys.local, JSON.stringify(convertData));
	}

	async setCurrentPayToUser (userId: number) {
		this.payments[userId] = this.bs.decodeMoney(this.currentPay);

		this.renewCurrentPayment();
		await this.savePayments();
	}

	activeUsers () {
		this.userActive = true;
	}

	activePay () {
		this.payActive = true;
	}

	activeSeparation () {
		this.separationActive = true;
	}

	changeStep (e) {
		if (e.selectedIndex === 2) {
			this.selectUser(-1);
		}
	}

	recount (e) {
		if (e.value === 0) {
			this.tip = null;
			this.allSum = null;
		}

		this.tmpFieldsSave();
	}

	saveRow () {
		this.addRowSwitcher();

		this.row.users = [...this.userRate];

		const data = this.bs.validateProcess(this.row);

		console.log('afterConvert', data);

		if (this.row.id === undefined) {
			data.id = this.rows.length;
			this.rows.push(data);
		} else {
			const currentRow = this.rows.find(item => {
				return item.id === data.id;
			});

			Object.keys(currentRow).forEach(name => {
				currentRow[name] = undefined;
			});

			Object.assign(currentRow, data);
		}

		this.clearRow();
		this.dataSource = new MatTableDataSource<Element>(this.rows);

		this.getRowsSum();

		window.localStorage.setItem(this.keys.rows, JSON.stringify(this.rows));

		this.activeTab = 0;

		// this.activeInput(this._inputElement);

		// console.log(this.rows);
	}

	clearRow () {

		this.canSave = true;

		this.row = {
			id:       undefined,
			name:     '',
			cost:     null,
			quantity: null,
			gSum:     null,
			empty:    '',
			users:    this.userList.map(() => 1),
		};
	}

	delRow () {

		if (this.row.id || this.row.id === 0) {

			this.rows.splice(this.row.id, 1);

			// переписуєм індески строк
			let index = 0;
			this.rows = this.rows.map(item => {
				item.id = index;
				index++;

				return item;
			});

			this.clearRow();
			this.dataSource = new MatTableDataSource<Element>(this.rows);

			this.getRowsSum();

			window.localStorage.setItem(
				this.localKey,
				JSON.stringify(this.rows),
			);
		} else {
			this.clearRow();
		}
	}

	editRow (row) {
		this.addingRow = true;

		this.stepNumber = [mode.GROUP_EDIT, mode.GROUP].includes(this.creationMode) ? 2 : 3;

		this.clearRow();
		this.canSave = false;

		this.row = this.bs.convertRowToForm(row);

		if (this.row.users.length !== this.userList.length) {
			for (let i = this.row.users.length; i < this.userList.length; i++) {
				this.row.users[i] = null;
			}
		}

		const countCustomPayment = this.row.users.filter(payment => {
			return !(payment === 1 || !payment);
		}).length;

		if (countCustomPayment) {
			this.userCheckMode = 2;
			this.userColor = 'accent';
		} else {
			this.userCheckMode = 1;
			this.userColor = 'primary';
		}
	}

	getRowsSum () {
		this.rowsSum = this.rows.reduce((acc, row) => {

			return acc + (row.gSum || this.bs.cGetGSum(this.bs.decodeMoney(row.cost), this.bs.decodeQty(row.quantity) || 1));
		},                              0);

		return this.rowsSum;
	}

	addRowSwitcher () {
		return this.addingRow = !this.addingRow;
	}

	cancelRow () {
		this.addRowSwitcher();
		this.clearRow();
	}

	deleteRow () {
		this.addRowSwitcher();

		this.delRow();
	}

	cleanTmpStorage () {
		const promises = Object.values(this.keys).map(key => this.storage.del(key));

		return Promise.all(promises);
	}
}
