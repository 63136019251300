<mat-card class="float-card">
  @if (!showFinish) {
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="container">
          <mat-form-field class="first-row">
            <input matInput formControlName="pass" name="pass"
              type="password" placeholder="Новый пароль" maxlength="100">
          </mat-form-field>
          @if (form.get('pass').invalid && form.get('pass').touched) {
            <p class="title">
              Не верная длина пароля
            </p>
          }
        </div>
        <div class="button-row first-row">
          <button mat-raised-button [disabled]="form.invalid" type="submit" color="primary">
            Зберегти пароль
          </button>
        </div>
      </form>
    </mat-card-content>
  } @else {
    <mat-card-content>
      <h2>Пароль изменен.</h2>
      <h3>Через {{redirectTime}} секунд Вы будете переадресованы на <a routerLink="/login">страницу логина</a>
    </h3>
  </mat-card-content>
}
<mat-divider></mat-divider>
<mat-card-actions>
  <a mat-stroked-button color="primary" routerLink="/registration">
    Реєстрація
  </a>
  <a mat-stroked-button color="primary" routerLink="/login">
    Вхід
  </a>
</mat-card-actions>
</mat-card>
