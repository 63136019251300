<mat-card class="float-card">
  <mat-card-title>Подтверждение email</mat-card-title>
  <mat-card-content>
    @if (result) {
      <div class="col s12">
        <h3 class="{{result.type ? 'green-text' : 'red-text'}}">{{ result.message }}</h3>
        @if (!result.type) {
          <div>Попробуйте войти или зарегистрироваться.</div>
        }
        @if (result.type) {
          <div>Вы будете перенаправленны на страницу входа через {{redirectTime}} секунд</div>
        }
      </div>
    }
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions>
    <a mat-stroked-button color="primary" routerLink="/registration">
      Реєстрація
    </a>
    <a mat-stroked-button color="primary" routerLink="/login">
      Вхід
    </a>
  </mat-card-actions>
</mat-card>
