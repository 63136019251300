import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // <-- NgModel lives here
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';

import { PaymentDeleteComponent } from './payment-delete/payment-delete.component';
import { PaymentShowComponent } from './payment-show/payment-show.component';

import { environment } from '../../environments/environment';
import { DialogComponent } from '../common/components/dialog/dialog.component';
import { ErrorShowerComponent } from '../common/components/error-shower/error-shower.component';
import { PreloaderComponent } from '../common/components/preloader/preloader.component';
import { MoneyPipe } from '../common/money.pipe';
import { QtyPipe } from '../common/qty.pipe';
import { AuthConfirmEmailComponent } from './auth-confirm-email/auth-confirm-email.component';
import { AuthLoginComponent } from './auth-login/auth-login.component';
import { AuthNewPassComponent } from './auth-new-pass/auth-new-pass.component';
import { AuthPasswordResetComponent } from './auth-password-reset/auth-password-reset.component';
import { AuthRegistrationComponent } from './auth-registration/auth-registration.component';
import { BillShareComponent } from './bill-share/bill-share.component';
import { BillShowShareComponent } from './bill-show-share/bill-show-share.component';
import { tBillsComponent } from './bills-test/t-bills.component';
import { BillsComponent } from './bills/bills.component';
import { CheckCreateComponent } from './check-create/check-create.component';
import { GroupCreateComponent } from './group-create/group-create.component';
import { GroupDeleteComponent } from './group-delete/group-delete.component';
import { GroupMembersComponent } from './group-members/group-members.component';
import { GroupShowComponent } from './group-show/group-show.component';
import { GroupsComponent } from './groups/groups.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PaymentCreateComponent } from './payment-create/payment-create.component';
import { RefundComponent } from './refund/refund.component';
import { StartPageComponent } from './start-page/start-page.component';
import { SystemRoutingModule } from './system-routing.module';
import { SystemComponent } from './system.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { UserInfoComponent } from './user-info/user-info.component';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { UserModelService } from '../common/models/user-model.service';
import { AuthService } from '../common/services/auth.service';
import { BillService } from '../common/services/bill.service';
import { HelperService } from '../common/services/helper.service';
import { RequesterService } from '../common/services/requester.service';

@NgModule({
	declarations: [
		SystemComponent,
		CheckCreateComponent,
		GroupCreateComponent,
		StartPageComponent,
		GroupMembersComponent,
		BillsComponent,
		tBillsComponent,
		MoneyPipe,
		QtyPipe,
		AuthLoginComponent,
		AuthRegistrationComponent,
		GroupsComponent,
		GroupShowComponent,
		BillShareComponent,
		BillShowShareComponent,
		NotFoundComponent,
		AuthConfirmEmailComponent,
		RefundComponent,
		UserInfoComponent,
		AuthPasswordResetComponent,
		AuthNewPassComponent,
		UnsubscribeComponent,
		GroupDeleteComponent,
		DialogComponent,
		PreloaderComponent,
		ErrorShowerComponent,
		PaymentCreateComponent,
		PaymentDeleteComponent,
		PaymentShowComponent,
	],
	bootstrap: [SystemComponent], imports:   [
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		FlexLayoutModule,
		MatMenuModule,
		MatButtonModule,
		MatCheckboxModule,
		MatToolbarModule,
		MatIconModule,
		MatSelectModule,
		MatSidenavModule,
		MatCardModule,
		MatInputModule,
		MatTableModule,
		MatListModule,
		MatExpansionModule,
		MatDialogModule,
		MatChipsModule,
		MatTabsModule,
		MatTooltipModule,
		MatRadioModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatSlideToggleModule,
		MatGridListModule,
		MatButtonToggleModule,
		MatStepperModule,
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production}),
		SystemRoutingModule,
		MatAutocompleteModule,
	], providers: [
		HelperService,
		RequesterService,
		BillService,
		AuthService,
		UserModelService,
		provideHttpClient(withInterceptorsFromDi()),
	]
})
export class SystemModule {
}
