<mat-card class="float-card">
  @if (this.editable) {
    <mat-card-content>
      <div class="field-keeper">
        <mat-form-field>
          <mat-label>Назва</mat-label>
          <input matInput name="name" type="text"
            [(ngModel)]="name">
        </mat-form-field>
      </div>
      <mat-form-field>
        <mat-label>Хто повертає</mat-label>
        <mat-select [value]="userFrom.id"
          (selectionChange)="changeUser($event, 1)">
          @for (user of users; track user) {
            <mat-option [value]="user.id">{{ user.name }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <div class="field-keeper">
        <mat-form-field>
          <mat-label>Сума</mat-label>
          <input matInput name="amount" type="number" min="0"
            [(ngModel)]="amount">
        </mat-form-field>
      </div>
      <div class="field-keeper">
        <mat-form-field>
          <mat-label>Дата</mat-label>
          <input matInput name="amount" type="datetime-local"
            [(ngModel)]="date">
        </mat-form-field>
      </div>
      <mat-form-field>
        <mat-label>Кому повертає</mat-label>
        <mat-select [value]="userTo.id"
          (selectionChange)="changeUser($event, 0)">
          @for (user of users; track user) {
            <mat-option [value]="user.id">{{ user.name }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-card-subtitle>
        <div class="both-button-row">
          <button type="button" mat-raised-button color="primary" (click)="refund()">
            Зберегти
          </button>
        </div>
      </mat-card-subtitle>
    </mat-card-content>
  } @else {
    <mat-card-content>
      <div>
        <div class="date">
          <mat-icon>event</mat-icon>
          <span>{{ date }}</span>
        </div>
      </div>
      <mat-card-title>
        <mat-icon class="inline-icon" matListItemIcon>redo</mat-icon>
      {{ name }}</mat-card-title>
      <mat-card-subtitle>
        Хто повертає: <b>{{ userFrom.name }}</b>
      </mat-card-subtitle>
      <mat-card-subtitle>
        Сума: <b>{{ amount }}</b>
      </mat-card-subtitle>
      <mat-card-subtitle>
        Кому повертає: <b>{{ userTo.name }}</b>
      </mat-card-subtitle>
    </mat-card-content>
  }
</mat-card>
