import packageInfo from './../../.version.json';

export const environment = {
	production:     true,
	originHostName: 'debtors-hole.com',
	host:           `${window.location.origin}`,
	apiUrl:         `${window.location.origin}/api`,
	frontUrl:       `${window.location.origin}`,
	auth:           {
		google:   '1000979528440-eh9p3h6mmk4cjbro417a54ogd7psid16.apps.googleusercontent.com',
		facebook: '2179608665613879'
	},
	messageHosts: [
		'' || `${window.location.origin}`,
	],
	startURL: '/bills/list',
	version:  packageInfo.version,
};
