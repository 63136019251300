<mat-card class="float-card">
	<mat-card-content>
		<mat-vertical-stepper #stepper [selectedIndex]="stepNumber" (selectionChange)="changeStep($event)">
			<mat-step [hasError]="(operationType
			 ? !sum || (allSum == null && tip == null) ? false : sum > (allSum || sum + tip)
			 : !sum)" [completed]="!!sum">
				<ng-template matStepLabel>
					<mat-icon class="centered-icon">{{ operationType ? 'receipt' : 'payments' }}</mat-icon>
					{{ payment.name ? payment.name : 'Загальні дані' }}
					: {{ (bs.convert(allSum) || (tip ? bs.cGetAllSum(sum, tip) : null) || bs.convert(sum) || 0) | money }}
				</ng-template>
				<form class="">
					<mat-form-field>
						<mat-label>Тип</mat-label>
						<mat-select [(value)]="operationType" (selectionChange)="recount($event)" [disabled]="ids.payment">
							@for (type of types; track type) {
								<mat-option [value]="type.id">{{ type.name }}</mat-option>
							}
						</mat-select>
					</mat-form-field>
					<div class="field-keeper">
						<mat-form-field>
							<mat-label>Назва</mat-label>
							<input matInput name="name"
								   [(ngModel)]="payment.name" (blur)="tmpFieldsSave()">
						</mat-form-field>
					</div>
					<div class="field-keeper">
						<mat-form-field>
							<mat-label>Сума</mat-label>
							<input matInput type="number" name="sum" min="0"
								   [(ngModel)]="sum" (blur)="tmpFieldsSave()" required>
							@if (!sum) {
								<mat-error>Обов'язкове поле</mat-error>
							}
						</mat-form-field>
					</div>
					@if (operationType === 1) {
						<div class="field-keeper">
							@if (!allSum) {
								<mat-form-field>
									<mat-label>Чай: (10% = {{ bs.cGetClearTips(sum) | money }})</mat-label>
									<input matInput
										   [(ngModel)]="tip" (blur)="tmpFieldsSave()"
										   type="number" min="0"
										   name="tip"
									>
								</mat-form-field>
							} @else {
								<mat-form-field>
									<mat-label>Чай: (10% = {{ bs.cGetClearTips(sum) | money }})</mat-label>
									<input matInput name="tipsSumSlave" disabled
										   [value]="bs.cGetTips(allSum, sum) | money"
									>
								</mat-form-field>
							}
						</div>
						<div class="field-keeper" *ngIf="operationType === 1">
							@if (!tip) {
								<mat-form-field>
									<mat-label>Всього сплачено: ({{ bs.cGetClearAllSum(sum) | money }})</mat-label>
									<input matInput [(ngModel)]="allSum" (blur)="tmpFieldsSave()" type="number"
										   [min]="allSum ? sum : 0"
										   name="allSum"
									>
									@if (allSum < sum) {
										<mat-error>Обов'язкове поле</mat-error>
									}
								</mat-form-field>
							} @else {
								<mat-form-field>
									<mat-label>Всього сплачено: ({{ bs.cGetClearAllSum(sum) | money }})</mat-label>
									<input matInput disabled name="allSumSlave"
										   [value]="bs.cGetAllSum(sum, tip) | money"
									>
								</mat-form-field>
							}
						</div>
					}
					<div class="field-keeper">
						<mat-form-field>
							<mat-label>Дата</mat-label>
							<input matInput name="date" type="datetime-local"
								   [(ngModel)]="date" (blur)="tmpFieldsSave()">
						</mat-form-field>
					</div>
					<div class="field-keeper">
						<mat-form-field>
							<mat-label>Додатковий опис</mat-label>
							<textarea matInput name="desc" (blur)="tmpFieldsSave()" [(ngModel)]="payment.desc">
              </textarea>
						</mat-form-field>
					</div>
				</form>
				<mat-divider></mat-divider>
				<div class="first-row button-row">
					<button mat-raised-button color="accent" matStepperNext>Далі</button>
				</div>
			</mat-step>
			@if (![modes.GROUP, modes.GROUP_EDIT].includes(creationMode)) {
				<mat-step
					[hasError]="userActive && !userList.length"
					[completed]="!!userList.length"
					(interacted)="activeUsers()"
				>
					<ng-template matStepLabel>Учасники</ng-template>
					<div>
						<div style="position: relative;">
							<mat-list role="list">
								@for (user of userList; track user; let i = $index) {
									<mat-list-item role="listitem">
										<div matListItemTitle>{{ i + 1 }}. {{ user.name }}</div>
										@if (creationMode < modes.GROUP) {
											<button matListItemMeta mat-icon-button (click)="editUser(i)">
												<mat-icon class="mat-24">mode_edit</mat-icon>
											</button>
										}
										<mat-divider></mat-divider>
									</mat-list-item>
								}
							</mat-list>
						</div>
						@if (creationMode < modes.GROUP) {
							<div class="first-row button-row">
								<mat-form-field appearance="outline">
									<mat-label>Введіть ім'я</mat-label>
									<input matInput #userInput [(ngModel)]="userName" name="userName">
									<button type="button" mat-icon-button matSuffix color="primary" name="addUser"
											(click)="saveUser()">
										<mat-icon>add</mat-icon>
									</button>
								</mat-form-field>
							</div>
						}
					</div>
					<mat-divider></mat-divider>
					<div class="first-row button-row">
						<button mat-raised-button color="accent" (click)="savePayments()" matStepperPrevious>Назад
						</button>
						<button mat-raised-button color="accent" (click)="savePayments()" matStepperNext>Далі</button>
					</div>
				</mat-step>
			}
			<mat-step [hasError]="payActive && currentPay !== 0" (interacted)="activePay()">
				<ng-template matStepLabel>Оплата: {{ this.paidUsers }}</ng-template>
				<div id="payments" class="both-button-row">
					<div>
						Всього сплачено: {{ this.payment.allSum | money }}
					</div>
					<div>
						Залишилось внести: {{ currentPay | money }}
					</div>
				</div>
				@for (user of userList; track user) {
					<div class="">
						<mat-form-field>
							<mat-label>{{ user.name }}</mat-label>
							<input (input)="renewCurrentPayment()" matInput type="number"
								   [(ngModel)]="payments[user.id]"
								   name="{{'p' + user.id}}"
								   min="0"
								   max="{{bs.decodeMoney(this.payment.allSum)}}"
								   (blur)="savePayments()">
							<button mat-icon-button matSuffix (click)="setCurrentPayToUser(user.id)">
								<mat-icon>keyboard_return</mat-icon>
							</button>
						</mat-form-field>
					</div>
				}
				<mat-divider></mat-divider>
				<div class="first-row button-row">
					<button mat-raised-button color="accent" (click)="savePayments()" matStepperPrevious>Назад</button>
					<button mat-raised-button color="accent" (click)="savePayments()" matStepperNext>Далі</button>
				</div>
			</mat-step>
			<mat-step [hasError]="separationActive && bs.convert(sum) !== getRowsSum()"
					  (interacted)="activeSeparation()">
				<ng-template matStepLabel>Поділ</ng-template>
				@switch (operationType) {
					@case (TYPES.PAYMENT) {
						<div class="both-button-row">
							<mat-button-toggle class="lefter" (click)="removeChecked(); removeCheckedActive=true"
											   [checked]="removeCheckedActive===true">
								<mat-icon>check_box_outline_blank</mat-icon>
							</mat-button-toggle>

							<mat-button-toggle-group (change)="changeMode($event.value)">
								<mat-button-toggle [value]="1" [checked]="userCheckMode==1">
									швидко
								</mat-button-toggle>
								<mat-button-toggle [value]="2" [checked]="userCheckMode==2">
									точно
								</mat-button-toggle>
							</mat-button-toggle-group>

							<mat-button-toggle class="righter" (click)="checkAll(); removeCheckedActive=false"
											   [checked]="removeCheckedActive===false">
								<mat-icon>check_box</mat-icon>
							</mat-button-toggle>


							@if (userCheckMode !== 2) {
								<div class="both-button-row">
									<!--				<mat-chip-list [multiple]="true" [selectable]="true" (change)="selectUser($event)">-->
									<mat-chip-set>
										@for (user of userList; track user; let i = $index) {
											<mat-chip-option
												[color]="userColor"
												[value]="user.id"
												[selected]="!!userRate[user.id]"
												(click)="selectUser(user.id)">
												{{ i + 1 }}: {{ user.name }}: {{ tmpSeparatedSums[user.id] }}
											</mat-chip-option>
										}
									</mat-chip-set>
								</div>
							} @else {
								<div class="both-button-row">
									Пропорційний поділ
								</div>
								@if (!userList || !userList.length) {
									<div>
										Спочатку створіть участника<br>
										<div class="button-row first-row">
											<button type="button" mat-raised-button color="primary"
													(click)="activeTab=3">
												Створити
											</button>
										</div>
									</div>
								}
								<div class="vertical-holder">
									@for (user of userList; track user) {
										<div class="button-row">
									  <span class="sides">
										<button mat-mini-fab color="primary" (click)="selectUser(user.id, false)"
												[disabled]="checkActiveButton(user.id)">
										  <mat-icon>exposure_neg_1</mat-icon>
										</button>
									  </span>
											<mat-form-field>
												<mat-label>{{ user.name }}: {{ tmpSeparatedSums[user.id] }}</mat-label>
												<input matInput type="number"
													   [(ngModel)]="userRate[user.id]"
													   name="{{'u' + user.id}}">
											</mat-form-field>
											<span class="sides">
										<button mat-mini-fab color="primary" (click)="selectUser(user.id, true)">
										  <mat-icon>exposure_plus_1</mat-icon>
										</button>
								  	</span>
										</div>
									}
								</div>
							}
							<mat-divider></mat-divider>
							<div class="first-row button-row">
								<button mat-raised-button color="accent" matStepperPrevious>Назад</button>
								<button type="button" mat-raised-button color="primary" (click)="createPayment()">
									Зберегти рахунок
								</button>
							</div>
						</div>
					}
					@case (TYPES.BILL) {
						<div class="both-button-row">
							<div>
								Сплачено за рахунком: {{ bs.convert(sum) | money }}
							</div>
							<div>
								Залишилось внести: {{ bs.convert(sum) - getRowsSum() | money }}
							</div>
						</div>
						@if (addingRow) {
							<div class="both-button-row">
								<mat-button-toggle class="lefter" (click)="removeChecked(); removeCheckedActive=true"
												   [checked]="removeCheckedActive===true">
									<mat-icon>check_box_outline_blank</mat-icon>
								</mat-button-toggle>

								<mat-button-toggle-group (change)="changeMode($event.value)">
									<mat-button-toggle [value]="1" [checked]="userCheckMode==1">
										швидко
									</mat-button-toggle>
									<mat-button-toggle [value]="2" [checked]="userCheckMode==2">
										точно
									</mat-button-toggle>
								</mat-button-toggle-group>

								<mat-button-toggle class="righter" (click)="checkAll(); removeCheckedActive=false"
												   [checked]="removeCheckedActive===false">
									<mat-icon>check_box</mat-icon>
								</mat-button-toggle>


								@if (userCheckMode !== 2) {
									<div class="both-button-row">
										<!--				<mat-chip-list [multiple]="true" [selectable]="true" (change)="selectUser($event)">-->
										<mat-chip-set>
											@for (user of userList; track user; let i = $index) {
												<mat-chip-option
													[color]="userColor"
													[value]="user.id"
													[selected]="!!userRate[user.id]"
													(click)="selectUser(user.id)">
													{{ i + 1 }}: {{ user.name }}: {{ tmpSeparatedSums[user.id] }}
												</mat-chip-option>
											}
										</mat-chip-set>
									</div>
								} @else {
									<div class="both-button-row">
										Пропорційний поділ
									</div>
									@if (!userList || !userList.length) {
										<div>
											Спочатку створіть участника<br>
											<div class="button-row first-row">
												<button type="button" mat-raised-button color="primary"
														(click)="activeTab=3">
													Створити
												</button>
											</div>
										</div>
									}
									<div class="vertical-holder">
										@for (user of userList; track user) {
											<div class="button-row">
									  <span class="sides">
										<button mat-mini-fab color="primary" (click)="selectUser(user.id, false)"
												[disabled]="checkActiveButton(user.id)">
										  <mat-icon>exposure_neg_1</mat-icon>
										</button>
									  </span>
												<mat-form-field>
													<mat-label>{{ user.name }}: {{ tmpSeparatedSums[user.id] }}
													</mat-label>
													<input matInput type="number"
														   [(ngModel)]="userRate[user.id]"
														   name="{{'u' + user.id}}">
												</mat-form-field>
												<span class="sides">
										<button mat-mini-fab color="primary" (click)="selectUser(user.id, true)">
										  <mat-icon>exposure_plus_1</mat-icon>
										</button>
								  	</span>
											</div>
										}
									</div>
								}
								<mat-divider></mat-divider>

								<div class="both-button-row">
									№ {{ row.id === undefined ? rows.length + 1 : row.id + 1 }}
								</div>
								<mat-form-field>
									<mat-label>Назва</mat-label>
									<input matInput #activeInput [(ngModel)]="row.name" name="name">
								</mat-form-field>
								@if (row.quantity === null || row.gSum === null) {
									<mat-form-field>
										<mat-label>Вартісьт за одиницю</mat-label>
										<input matInput type="number"
											   (change)="tmpUserPayments()"
											   [(ngModel)]="row.cost" name="formCost" min="0"
										>
									</mat-form-field>
								} @else {
									<mat-form-field>
										<mat-label>Вартісьт за одиницю</mat-label>
										<input matInput name="countCost" disabled
											   [value]="bs.cGetCost(row.gSum, row.quantity) | money"
										>
									</mat-form-field>
								}
								@if (row.cost === null || row.gSum === null) {
									<mat-form-field>
										<mat-label>Кількість</mat-label>
										<input matInput type="number"
											   (change)="tmpUserPayments()"
											   [(ngModel)]="row.quantity" min="0"
											   name="formQuantity"
										>
									</mat-form-field>
								} @else {
									<mat-form-field>
										<mat-label>Кількість</mat-label>
										<input matInput name="countQuantity" disabled
											   [value]="bs.cGetQuantity(row.gSum, row.cost) | qty">
									</mat-form-field>
								}
								@if (row.quantity === null || row.cost === null) {
									<mat-form-field>
										<mat-label>Сума всього</mat-label>
										<input matInput type="number"
											   (change)="tmpUserPayments()"
											   [(ngModel)]="row.gSum" min="0"
											   name="formGSum">
									</mat-form-field>
								} @else {
									<mat-form-field>
										<mat-label>Сума всього</mat-label>
										<input matInput name="countGSum" disabled
											   [value]="bs.cGetGSum(row.cost, row.quantity) | money">
									</mat-form-field>
								}
								<div class="button-row">
									<button type="button" mat-raised-button color="accent" (click)="deleteRow()">
										Видалити
									</button>
									<button type="button" mat-raised-button color="primary" (click)="cancelRow()">
										Відміна
									</button>
									<button type="button" mat-raised-button color="primary" (click)="saveRow()">
										Зберегти
									</button>
								</div>
							</div>
						} @else {
							<div class="button-row">
								<button type="button" mat-raised-button color="primary" (click)="addRowSwitcher()">
									Додати позицію
								</button>
							</div>
							<mat-divider></mat-divider>
							<div class="first-row button-row">
								<button mat-raised-button color="accent" matStepperPrevious>Назад</button>
								<button type="button" mat-raised-button color="primary" (click)="createPayment()">
									Зберегти рахунок
								</button>
							</div>
						}
					}
				}
			</mat-step>
		</mat-vertical-stepper>
	</mat-card-content>
</mat-card>
@if (operationType === TYPES.BILL) {
	<div style="overflow-x:auto">
		<table mat-table #table [dataSource]="dataSource">
			<ng-container matColumnDef="position">
				<th mat-header-cell
					*matHeaderCellDef> №
				</th>
				<td mat-cell *matCellDef="let i; let index=index;">
					{{ index + 1 }}
				</td>
			</ng-container>
			<!-- Name Column -->
			<ng-container matColumnDef="name">
				<th mat-header-cell class="middle-cell" *matHeaderCellDef>
					Назва
				</th>
				<td mat-cell *matCellDef="let element">
					{{ element.name }}
				</td>
			</ng-container>
			<ng-container matColumnDef="cost">
				<th mat-header-cell *matHeaderCellDef>Варт.</th>
				<td mat-cell *matCellDef="let element">
					{{ element.cost ? (element.cost | money) : null }}
				</td>
			</ng-container>
			<ng-container matColumnDef="quantity">
				<th mat-header-cell *matHeaderCellDef>Кіл.</th>
				<td mat-cell *matCellDef="let element">
					{{ element.quantity ? (element.quantity | qty) : null }}
				</td>
			</ng-container>
			<!-- gSum Column -->
			<ng-container matColumnDef="gSum">
				<th mat-header-cell *matHeaderCellDef>Загалом</th>
				<td mat-cell *matCellDef="let element">
					{{
                        element.gSum
                            ? (element.gSum | money)
                            : null
					}}
				</td>
			</ng-container>

			<!-- Weight Column -->
			@for (user of userList; track user) {
				<ng-container matColumnDef="{{'u' + user.id}}">
					<th mat-header-cell *matHeaderCellDef>{{ user.name }}</th>
					<td mat-cell *matCellDef="let element">
						{{ (element.users[user.id] | qty) || '' }}
					</td>
				</ng-container>
			}
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let cRow; columns: displayedColumns;"
				(click)="editRow(cRow)"
				[ngStyle]="{
				backgroundColor:  writingUsers(cRow.users) >= (cRow.quantity || 1 ) ? '' : 'beige',
				border: row.id === cRow.id ? '1px inset' : '',
				opacity: row.id === cRow.id ? '0.5' : ''
			}"
			></tr>
		</table>
	</div>
}
