import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
	selector:    'app-dialog',
	templateUrl: './dialog.component.html',
	styleUrls:   ['./dialog.component.css']
})
export class DialogComponent {

	constructor(
		public dialogRef: MatDialogRef<DialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		dialogRef.disableClose = this.data.closeByButton;
	}

	onLeftClick() {
		this.dialogRef.close({ type: 'leftClick', data: this.data });
	}

	onRightClick() {
		this.dialogRef.close({ type: 'rightClick', data: this.data });
	}

	closeClick() {
		this.dialogRef.close({ type: 'closeClick', data: this.data });
	}
}
