@if (!!user) {
  <mat-card class="float-card">
    <mat-card-content>
      <mat-icon class="user-photo">account_circle</mat-icon>
      <mat-card-title class="center-icon">
        {{ user.name }}
        <button mat-icon-button (click)="editUser()">
          <mat-icon>mode_edit</mat-icon>
        </button>
      </mat-card-title>
      <mat-card-subtitle>
        email: {{ user.email }}
      </mat-card-subtitle>
      <mat-list>
        <mat-list-item class="border">
          <mat-icon matListItemIcon>email</mat-icon>
          <div matListItemTitle>Ел. пошта: <b>{{ user.email }}</b></div>
          <!--<mat-icon click="editUser(user.id)">mode_edit</mat-icon>-->
        </mat-list-item>
        <mat-list-item class="border">
          <mat-icon matListItemIcon>notifications</mat-icon>
          <h4 matListItemTitle>Нотифікація:</h4>
          <span matListItemMeta >
            <mat-slide-toggle
              class="example-margin"
              (toggleChange)="toggleNotification()"
              [color]="color"
              [ngModel]="notification"
              [disabled]="disabled">
            </mat-slide-toggle>
          </span>
        </mat-list-item>
        <!--		<mat-list-item class="border">-->
        <!--			<mat-icon mat-list-icon>location_on</mat-icon>-->
        <!--			<h4 mat-line>Местоположение:</h4>-->
        <!--			<mat-slide-toggle-->
        <!--				class="example-margin"-->
        <!--				(toggleChange)="locationTogle()"-->
        <!--				[color]="color"-->
        <!--				[ngModel]="location"-->
        <!--				[disabled]="loc_disabled">-->
      <!--			</mat-slide-toggle>-->
    <!--		</mat-list-item>-->
    <mat-list-item>
      <p class="button-row first-row" style="width: 100%; text-align: right;">
        <button mat-raised-button (click)="logout()" color="primary">Вийти</button>
      </p>
    </mat-list-item>
    @if (isShowError) {
      <mat-list-item>
        <p class="button-row first-row" style="width: 100%;">
          <button mat-raised-button (click)="safariaClick()" color="primary">сафаря</button>
        </p>
      </mat-list-item>
    }
  </mat-list>
</mat-card-content>
</mat-card>
}
