import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';

import { DialogComponent } from '../../common/components/dialog/dialog.component';
import { UserModelService } from '../../common/models/user-model.service';
import { AuthService } from '../../common/services/auth.service';
import { MetaInfoService } from '../../common/services/meta-info.service';
import { RequesterService } from '../../common/services/requester.service';
import { StorageService } from '../../common/services/storage.service';

@Component({
	selector:    'app-user-info',
	templateUrl: './user-info.component.html',
	styleUrls:   ['./user-info.component.css'],
})
export class UserInfoComponent implements OnInit {

	user: { name: string, email: string };
	color = 'primary';
	loaderShow = true;
	disabled = false;
	notification = false;
	subscription;
	isShowError;

	location = false;
	loc_disabled = true;
	private _storageKey = 'show_net_errors';

	constructor (
		private router: Router,
		private requester: RequesterService,
		private swPush: SwPush,
		private auth: AuthService,
		public dialog: MatDialog,
		public userModel: UserModelService,
		public metaInfo: MetaInfoService,
		private storage: StorageService,
	) {
	}

	async ngOnInit () {

		await this.metaInfo.setData({
			rightMenu: {
				icon: 'cancel',
				href: '/bills/list',
			},
			title: 'Налаштування'
		});

		this.isShowError = await this.storage.get(this._storageKey);

		this.metaInfo.showPreloader(true);

		try {
			console.log('=-=-this.swPush', this.swPush);
			console.log('=-=- this.swPush.isEnabled', this.swPush.isEnabled);

			this.disabled = !this.swPush.isEnabled;

			this.swPush.subscription.subscribe(sub => {
				console.log('=-=- swPush.subscription.subscribe', sub);

				if (sub) {
					console.log('=-existed sub', sub.endpoint);
					this.subscription = sub;
					this.notification = true;
				}
			});

			this.swPush.messages.subscribe(messages => {
				console.log('=-=--swPush.messages.subscribe', messages);
			});

			this.swPush.notificationClicks.subscribe(messages => {
				console.log('=-=--swPush.notificationClicks.subscribe', messages);
			});
		} catch (e) {
			console.log('=-=--ngOnInit error', e);
		}

		this.requester.getUser()
		.then(userData => {
			this.userModel.createUser(userData)
			.then(user => {
				this.metaInfo.showPreloader(false);

				this.user = user;
			});

			this.loaderShow = false;
		})
		.catch(err => {
			this.metaInfo.showError(err);
		});
	}

	async toggleNotification () {
		console.log('=-toggleNotification', this.notification);

		if (this.disabled) {
			console.log('=-toggleNotification is disabled');

			return;
		}

		if (!this.notification) {
			console.log('=-toggleNotification try to subscribe to notifications');

			try {
				const sub = await this.swPush.requestSubscription({
					serverPublicKey: 'BCbA/Kpn6zYHuzOwgBKk2Jiv4PJ2RWieNNSBZNpEqc8wx7a21KJmSwqTw8JhDqbUXzFab8hXOEWmied3Tr/INEQ=',
				});

				console.log('=-new sub', sub.endpoint);

				if (!sub.endpoint) {
					throw new Error('Wrong format');
				}

				await this.userModel.createSub(sub)
				.catch(e => {
					console.log('=-toggleNotification api requester error', e);
					this.notification = false;

					return this.swPush.unsubscribe();
				});
			} catch (e) {
				console.log('=-toggleNotification sub error', e);
			}
		} else {
			this.notification = false;

			return this.unSub();
		}
	}

	locationTogle () {

	}

	unSub () {

		if (!this.subscription) {
			return;
		}

		return this.swPush.unsubscribe()
		.then(() => {
			this.requester.delUserSub(this.subscription)
			.then(() => {
				this.subscription = null;
			});
		});
	}

	async logout () {
		await this.auth.logout();
		await this.router.navigate(['login']);
	}

	editUser () {

		const dialogRef = this.dialog.open(DialogComponent, {
			width: '450px',
			data:  {
				showInput:     true,
				inputValue:    this.user.name,
				title:         'Редагування',
				rightBtnTitle: 'Зберегти',
				leftBtnTitle:  'Відміна',
				leftBtnIcon:   'cancel',
			},
		});

		dialogRef.afterClosed().subscribe(result => {

			if (result && result.type === 'rightClick') {
				this.userModel.updateUser({ name: result.data.inputValue})
				.then(response => {

					this.user.name = response.name;
				});
			}
		});
	}

	async safariaClick () {
		// / Use serviceWorker.ready to ensure that you can subscribe for push
		try {
			console.log('=-safariaClick ');
			if (!navigator.serviceWorker) {
				console.log('=-serviceWorker: nope ');

				return;
			}

			console.log('=-serviceWorker: wait till ready');
			const serviceWorkerRegistration = await navigator.serviceWorker.ready;
			console.log('=-serviceWorker: ready!! ', serviceWorkerRegistration);

			if (!serviceWorkerRegistration?.pushManager?.subscribe) {
				console.log('!!! pushManager is absent !!! :(');
				return;
			}

			const options = {
				userVisibleOnly:      true,
				applicationServerKey: null
			};

			const pubKey = 'BCbA/Kpn6zYHuzOwgBKk2Jiv4PJ2RWieNNSBZNpEqc8wx7a21KJmSwqTw8JhDqbUXzFab8hXOEWmied3Tr/INEQ=';

			const key = atob(pubKey.replace(/_/g, '/').replace(/-/g, '+'));
			const applicationServerKey = new Uint8Array(new ArrayBuffer(key.length));
			for (let i = 0; i < key.length; i++) {
				applicationServerKey[i] = key.charCodeAt(i);
			}
			options.applicationServerKey = applicationServerKey;

			const pushSubscription = await serviceWorkerRegistration.pushManager.subscribe(options);

			console.log('pushSubscription', pushSubscription.endpoint);
		} catch (e) {
			console.log('safariaClick error', e);
		}
	}
}
