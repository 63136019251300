<mat-card class="float-card text-center">
  @if (messageError) {
    <mat-card-content class="alert">
      <span class="closebtn" (click)="messageError = ''">&times;</span>
      {{ messageError }}
    </mat-card-content>
  }
  @if (!resReg) {
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div>
          <mat-form-field class="first-row">
            <input matInput formControlName="name" maxlength="100" name="name" type="text" placeholder="Name">
          </mat-form-field>
          @if (form.get('name').invalid && form.get('name').touched) {
            <p class="title">
              Поле не має бути пустим
            </p>
          }
        </div>
        <div>
          <mat-form-field>
            <input matInput formControlName="email" name="email" type="email" maxlength="100"
              placeholder="E-mail">
          </mat-form-field>
          @if (form.get('email').invalid && form.get('email').touched) {
            <p class="title">
              Не вірний формат E-mail
            </p>
          }
        </div>
        <div>
          <mat-form-field>
            <input matInput formControlName="password" type="password"
              name="password" placeholder="Password" maxlength="100">
          </mat-form-field>
          @if (form.get('password').invalid && form.get('password').touched) {
            <p class="title">
              {{ form.get('password')['errors']['required'] ? 'Поле не має бути пустим' : '' }}
              {{ form.get('password')['errors']['minlength'] ? 'Поле має бути не меньше ' +
              form.get('password')['errors']['minlength']['requiredLength'] : '' }}
            </p>
          }
        </div>
        <div class="button-row">
          <button mat-raised-button [disabled]="form.invalid" type="submit" color="primary">
            Зберегти
          </button>
        </div>
      </form>
    </mat-card-content>
  } @else {
    <mat-card-content>
      <h2>Реєстрація прошла успешно</h2>
      На Вашу пошту надіслано листа, для підтвердження реєстрації перейдіть за посиланням доданого в ньому
    </mat-card-content>
  }
  <mat-divider></mat-divider>
  <mat-card-actions class="block-center">
    <a mat-stroked-button color="primary" routerLink="/reset-pass">
      Скидання паролю
    </a>
    <a mat-stroked-button color="primary" routerLink="/login" color="primary">
      Вхід
    </a>
  </mat-card-actions>
</mat-card>
