import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { environment } from '../../../../environments/environment';
import { StorageService } from '../../services/storage.service';
import { DialogComponent } from '../dialog/dialog.component';
import { HttpResponseBase } from '@angular/common/http';

@Component({
	selector:    'app-error-shower',
	templateUrl: './error-shower.component.html',
	styleUrls:   ['./error-shower.component.css'],
})
export class ErrorShowerComponent implements OnInit {

	public isShowError = false;
	public showingError = [];
	private _console = console.log;

	private _storageKey = 'show_net_errors';

	@Input() set error (value: object) {
		this.checkAndShow(value);
	}

	@Input() set showError (value: boolean) {
		this._showError(value);
	}

	constructor (
		private _storage: StorageService,
		// private metaInfo: MetaInfoService,
		private dialog: MatDialog,
		// private auth: AuthService,
		private router: Router,
	) {
		console.log = this.consoleLog.bind(this);

		setInterval(() => {
			this.showingError = this.showingError.filter((item) => {
				const date = new Date(Object.keys(item)[0]);

				return !(Date.now() - (10 * 60 * 1000) > +date);
			});
		}, 5 * 60 * 1000);
	}

	async ngOnInit () {
		this.isShowError = await this._storage.get(this._storageKey);
	}

	checkAndShow (err: any) {
		if (err instanceof Error || err instanceof HttpResponseBase) {
			if (err['code'] === 'NEED_AUTH') {
				return this.router.navigate(['login']);
			}

			if (err['status'] === 404) {
				return this.router.navigate(['not-not-found']);
			}

			const simpleError = {
				message: err['message'],
				stack:   err['stack'],
			};
			this.showingError.push(simpleError);

			if (environment.host.startsWith('http://localhost')) {
				const date = new Date().toISOString();
				this._console(date, err);
			}

			const dialogRef = this.dialog.open(DialogComponent, {
				width: '450px',
				data:  {
					title:         'Щось пішло не так!',
					desc:          'При звернені до нашого серверу сталась помилка, спробуйте пізніше',
					rightBtnTitle: 'Ok',
				}
			});
		} else {
			this.consoleLog(err);
		}
	}

	async _showError (value: boolean) {
		if (value === null) {
			return;
		}
		this.isShowError = !!value;
		await this._storage.set(this._storageKey, this.isShowError);
	}

	consoleLog (...msgs) {
		if (environment.host.startsWith('http://localhost')) {
			const date = new Date().toISOString();
			this._console(date, JSON.stringify(msgs));
		}

		this.showingError.push({ [(new Date()).toISOString()]: msgs.length > 1 ? [...msgs].toString() : msgs[0] });
	}
}
