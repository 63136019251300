<mat-card class="float-card">
	<div>
		<mat-card-content>
            <h1>Боргова Яма</h1>
			<p>
                Ласкаво просимо до ЯМИ))<br>
                тепер ти завжди будеш комусь винен...
			</p>
			<p>
                Трохи про сервіс можна прочитати на
                <a href="https://landing.debtors-hole.com" target="_blank">офіційній сторінці</a>
			</p>
			<h2>p.s. Знаєш секретний код?</h2>
			<form class="example-form" ngForm="form" #form>
				<mat-form-field class="example-full-width">
					<input matInput ngModel="" #pass name="pass" placeholder="напиши його сюди" type="password"
						   value="">
				</mat-form-field>
				<div class="button-row">
					<button mat-raised-button type="submit" (click)="checkPass(pass.value)" color="primary">
                        Перевірити код
					</button>
				</div>
			</form>
		</mat-card-content>
		<mat-divider></mat-divider>
		<mat-card-actions class="center-block button-row">
			<a mat-stroked-button color="primary" routerLink="/registration">
				Реєстрація
			</a>
			<a mat-stroked-button color="primary" routerLink="/login">
				Вхід
			</a>
		</mat-card-actions>
		<!--<mat-divider></mat-divider>-->
		<div>
			<i>сборка</i>: <b>{{version}}/{{bacVersion}}</b> разработано в "Fancy-Four"
		</div>
<!--		<button mat-raised-button type="submit" (click)="test()" color="primary">Проверить-->
<!--			код-->
<!--		</button>-->
<!--		<a routerLink="/social-login">&nbsp;</a>-->
		<!--rowHeight1="2:1"-->
		<!--<mat-grid-list style="height: 100px" rowHeight="fit" [appResponsiveCols]="{xs: 1, sm: 2, md: 3, lg: 4, xl: 5}">-->
		<!--<mat-grid-tile style="background-color: red">1<br>2222</mat-grid-tile>-->
		<!--<mat-grid-tile style="background-color: #0021cb">2</mat-grid-tile>-->
		<!--<mat-grid-tile style="background-color: #74a5ce">3</mat-grid-tile>-->
		<!--<mat-grid-tile style="background-color: #23ff00">4</mat-grid-tile>-->
		<!--<mat-grid-tile style="background-color: #000000">5</mat-grid-tile>-->
		<!--</mat-grid-list>-->
	</div>
</mat-card>
