import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { DialogComponent } from '../../common/components/dialog/dialog.component';
import { UserModelService } from '../../common/models/user-model.service';
import { AuthService } from '../../common/services/auth.service';
import { BillService } from '../../common/services/bill.service';
import { HelperService } from '../../common/services/helper.service';
import { MetaInfoService } from '../../common/services/meta-info.service';
import { RequesterService } from '../../common/services/requester.service';
import { StorageService } from '../../common/services/storage.service';

export interface Element {
	id: number;
	name: string;
	cost: number;
	quantity: number;
	sumBD: number;
	disSum: number;
	disPercent: number;
	gSum: number;
	resolve?: string;  // поле которое было высчетанно автоматически
	rights?: number;
}

@Component({
	selector:    'app-group-show',
	templateUrl: './group-show.component.html',
	styleUrls:   ['./group-show.component.css']
})
export class GroupShowComponent implements OnInit {

	title = '***';

	groupId = this.currentRout.snapshot.params.groupId;
	shareUrl = this.currentRout.snapshot.params.shareUrl;

	noOneItem;
	group;
	bills;
	debtorsList;
	addToUser;
	rights;
	currency;

	rightMenu = [];

	CONFIG = [{
		method: 'getFullGroup',
		params: [this.groupId],
	},        {
		method: 'getSharedGroup',
		params: [this.shareUrl]
	}];

	config = this.groupId ? this.CONFIG[0] : this.CONFIG[1];

	constructor (
		private requester: RequesterService,
		private router: Router,
		private currentRout: ActivatedRoute,
		private billService: BillService,
		private auth: AuthService,
		public  metaInfo: MetaInfoService,
		public  dialog: MatDialog,
		public  userModelService: UserModelService,
		public  storage: StorageService,
		public  hs: HelperService,
	) {
	}

	ngOnInit () {
		this.metaInfo.showPreloader(true);

		this.requester[this.config.method](...this.config.params)
		.then((data: any) => {
			this.metaInfo.showPreloader(false);

			this.group = data;
			this.bills = data.bills;
			this.title = this.group.name;
			this.rights = data.rights;
			this.currency = data.currency;

			this.debtorsList = this.getUserDebtor();

			if (this.shareUrl) {

				// якщо група вже прив'язана то переходим на неї
				if (data.id) {
					return this.router.navigate(['groups', data.id]);
				}

				const user = this.userModelService.getUserFromCache();
				if (user) {
					this.addToUser = true;

					this.openDialog();
				}
			}

			if (data.rights > 0) {

				this.rightMenu.push({
					icon:  'note_add',
					title: 'Створити платіж',
					href:  `/groups/${ (this.groupId || this.shareUrl) }/payments/create`
				}, {
					icon:  'note_add',
					title: 'Редагувати',
					href:  `/groups/${(this.groupId || this.shareUrl)}/edit`
				}, {
					icon:  'note_add',
					title: 'Поділитися',
					href:  `/groups/${(this.groupId || this.shareUrl)}/share`
				});
			}

			if (data.rights > 1) {
				this.rightMenu.push({
					icon:  'note_add',
					title: 'Видалити',
					href:  `/groups/${(this.groupId || this.shareUrl)}/delete`
				});
			}

			this.metaInfo.setData({
				title:     'Група',
				rightMenu: this.rightMenu,
				leftMenu:  {
					icon: 'keyboard_backspace',
					href: `/groups/list`
				}
			});
		})
		.catch(err => {
			this.metaInfo.showError(err);
		});
	}

	getUserDebtor () {
		const result = Object.keys(this.group.debtors).map(userId => {

			const user = this.group.members.find(user => {

				return user.id === +userId || user.name === userId; // old logic, keep it for compatibility
			});

			return Object.keys(this.group.debtors[userId].debtors || { }).map(debtorId => {

				const debtor = this.group.members.find(user => {

					return user.id === +debtorId || user.name === debtorId; // old logic, keep it for compatibility;
				});

				return {
					userId:     user.id,
					userName:   user.name,
					debtorId:   debtor.id,
					debtorName: debtor.name,
					sum:        this.group.debtors[userId].debtors[debtorId].sum
				};
			});
		});

		return [].concat(...result);
	}

	refund (userFrom, userTo, amount) {
		return this.router.navigate([
			'groups', this.groupId, 'refund', amount, 'uf', userFrom, 'ut', userTo
		]);
	}

	openDialog (): void {
		const dialogRef = this.dialog.open(DialogComponent, {
			width: '450px',
			data:  {
				type:          'addGroup',
				desc:          'Бажаєте додати цю групу до свого облікового запису?',
				title:         'Загальна група',
				rightBtnTitle: 'Так',
				leftBtnTitle:  'Ні дякую',
				closeByButton: true
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			// console.log('The dialog was closed', result);

			if (result && result.type === 'rightClick') {
				this.requester.addSharedGroup(this.shareUrl)
				.then(async response => {
					await this.storage.del('addSharaLater');

					return this.router.navigate(['groups', response.groupNumber]);
				})
				.catch(async err => {

					await this.storage.set('addSharaLater', { type: 'group', id: this.shareUrl });

					if (err && err.code === 'NEED_AUTH') {
						return this.router.navigate(['login']);
					}

					throw err;
				});
			} else {
				this.storage.del('addSharaLater');
			}
		});
	}

	getBillUrl (item) {
		let url = '';

		switch (item.type) {
			case 'REFUND':
				url = 'refunds';
				break;
			default:
				url = 'payments';
		}

		if (this.shareUrl) {
			return `/share/${this.shareUrl}/group/${url}/${item.id}`;
		}

		return `/groups/${this.group.id}/${url}/${item.id}`;
	}

	getStateIcon (item) {
		if (item.settled) {
			return 'done_outline';
		}

		if (item.resolved) {
			return 'done_outline';
		}

		return 'edit';
	}

	getTypeIcon (item) {

		let url = '';

		switch (item.type) {
			case 'PAYMENT':
				url = 'payments';
				break;
			case 'REFUND':
				url = 'redo';
				break;
			default:
				url = 'receipt';
		}

		return url;
	}
}
