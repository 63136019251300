import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {RequesterService} from '../../common/services/requester.service';
import {MetaInfoService} from '../../common/services/meta-info.service';
import {HelperService} from '../../common/services/helper.service';

@Component({
	selector:    'app-groups',
	templateUrl: './groups.component.html',
	styleUrls:   ['./groups.component.css']
})
export class GroupsComponent implements OnInit {

	title = 'Список групп';
	private submenu = {
		icon: 'create_new_folder',
		href: `/groups/create`
	};
	noOneItem = false;

	groupsList = [];

	constructor(
		private requester: RequesterService,
		private router: Router,
		public  metaInfo: MetaInfoService,
		public  hs: HelperService,
	) {
	}

	ngOnInit() {
		this.metaInfo.showPreloader(true);

		this.metaInfo.setData({
			rightMenu: {
				icon: 'create_new_folder',
				href: `/groups/create`
			},
			title: 'Список групп'
		});

		return this.requester.getGroups()
		.then((data) => {
			this.metaInfo.showPreloader(false);

			this.noOneItem = !(data || data.length);
			this.groupsList = data;
		})
		.catch((err) => {
			this.metaInfo.showError(err);
		});
	}
}
