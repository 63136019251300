import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthConfirmEmailComponent } from './auth-confirm-email/auth-confirm-email.component';
import { AuthLoginComponent } from './auth-login/auth-login.component';
import { AuthNewPassComponent } from './auth-new-pass/auth-new-pass.component';
import { AuthPasswordResetComponent } from './auth-password-reset/auth-password-reset.component';
import { AuthRegistrationComponent } from './auth-registration/auth-registration.component';
import { BillShareComponent } from './bill-share/bill-share.component';
import { tBillsComponent } from './bills-test/t-bills.component';
import { BillsComponent } from './bills/bills.component';
import { GroupCreateComponent } from './group-create/group-create.component';
import { GroupDeleteComponent } from './group-delete/group-delete.component';
import { GroupMembersComponent } from './group-members/group-members.component';
import { GroupShowComponent } from './group-show/group-show.component';
import { GroupsComponent } from './groups/groups.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PaymentCreateComponent } from './payment-create/payment-create.component';
import { PaymentDeleteComponent } from './payment-delete/payment-delete.component';
import { PaymentShowComponent } from './payment-show/payment-show.component';
import { RefundComponent } from './refund/refund.component';
import { StartPageComponent } from './start-page/start-page.component';
import { SystemComponent } from './system.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { UserInfoComponent } from './user-info/user-info.component';

const routes: Routes = [
	// {path: '', redirectTo: '/bills/create', pathMatch: 'full'},

	{
		path:      '', component: SystemComponent, children:  [
			{ path: '', component: StartPageComponent },

			{ path: 'user-info', component: UserInfoComponent },

			{ path: 'bills/list', component: BillsComponent },
			{ path: 'bills/:id/refund/:amount/uf/:userFrom/ut/:userTo', component: RefundComponent },

			{ path: 'groups/list', component: GroupsComponent },
			{ path: 'groups/create', component: GroupCreateComponent },
			{ path: 'groups/:groupId/edit', component: GroupCreateComponent },
			{ path: 'groups/:id/members', component: GroupMembersComponent },
			{ path: 'groups/:groupId', component: GroupShowComponent },
			{ path: 'groups/:groupId/share', component: BillShareComponent },
			{ path: 'groups/:groupId/delete', component: GroupDeleteComponent },
			{ path: 'groups/:groupId/bills/:billId/refund/:amount/uf/:userFrom/ut/:userTo', component: RefundComponent, data: { type: 'singleCreate' }},

			{ path: 'groups/:groupId/refund/:amount/uf/:userFrom/ut/:userTo', component: RefundComponent, data: { type: 'groupCreate' }},
			{ path: 'groups/:groupId/refunds/:refundId', component: RefundComponent, data: { type: 'groupShow' }},
			{ path: 'groups/:groupId/refunds/:refundId/edit', component: RefundComponent, data: { type: 'groupEdit' }},
			{ path: 'groups/:groupId/refunds/:refundId/delete', component: PaymentDeleteComponent, data: { type: 'groupRefund' }},

			{ path: 'groups/:groupId/payments/create', component: PaymentCreateComponent },
			{ path: 'groups/:groupId/payments/:paymentId', component: PaymentShowComponent },
			{ path: 'groups/:groupId/payments/:paymentId/edit', component: PaymentCreateComponent },
			{ path: 'groups/:groupId/payments/:paymentId/delete', component: PaymentDeleteComponent, data: { type: 'groupPayment' } },

			{ path: 'payments/create', component: PaymentCreateComponent },
			{ path: 'payments/:paymentId', component: PaymentShowComponent },
			{ path: 'payments/:paymentId/edit', component: PaymentCreateComponent },
			{ path: 'payments/:paymentId/delete', component: PaymentDeleteComponent, data: { type: 'payment' } },
			{ path: 'payments/:paymentId/share', component: BillShareComponent },

			{ path: 'share/:shareUrl/payment', component: PaymentShowComponent },
			{ path: 'share/:shareUrl/group', component: GroupShowComponent },
			{ path: 'share/:shareUrl/group/payments/:paymentId', component: PaymentShowComponent },

			{ path: 'registration', component: AuthRegistrationComponent },
			{ path: 'login', component: AuthLoginComponent },
			{ path: 'reset-pass', component: AuthPasswordResetComponent },
			{ path: 'new-pass/:code', component: AuthNewPassComponent },
			{ path: 'list', component: tBillsComponent },
			{ path: 'confirm/:salt', component: AuthConfirmEmailComponent },

			{ path: 'unsubscribe', component: UnsubscribeComponent },

			{ path: 'not-found', component: NotFoundComponent },
			{ path: '**', component: NotFoundComponent }
		]
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { })],
	exports: [RouterModule]
})
export class SystemRoutingModule {
}
