@if (bill) {
	<div>
		<mat-card class="float-card">
			<mat-card-content>
				<div>
					<div class="date">
						<mat-icon>event</mat-icon>
						<span>{{ hs.formatDateTime(bill.date) }}</span>
					</div>
				</div>
				<mat-card-title>
					<mat-icon class="inline-icon" matListItemIcon>{{ getTypeIcon(bill) }}</mat-icon>
					{{ bill.name || '&nbsp;' }}
				</mat-card-title>
				@if (bill.type === TYPES.BILL) {
					<mat-card-subtitle>
						Рахунок:{{ bill.sum | money }}
						Чай:{{ bill.tip | money }}
					</mat-card-subtitle>
				}
				<mat-card-subtitle>
					Всього сплачено: <b>{{ (bill.allSum || bill.sum) | money }}</b>
				</mat-card-subtitle>
			</mat-card-content>
		</mat-card>
		<mat-card class="float-card">
			<mat-card-content>
				<mat-tab-group
					[selectedIndex]="activeTab"
					(selectedIndexChange)="setActiveTab($event)"
				>
					<mat-tab>
						<ng-template mat-tab-label>
							<mat-icon>{{ iconBill }}</mat-icon>
							Витрачено
						</ng-template>
						<div (swiperight)="swipe('right')" (swipeleft)="swipe('left')"
							 data-mc-options='{ "touchAction": "pan-y" }'>
							<mat-card-title-group>
								{{ checkSumBill ? '' : 'Не всі позиції з чека розподілені' }}
							</mat-card-title-group>
							@if (checkSumBill && checkPayments && !checkAllSumBill) {
								<mat-card-title-group>
									Виникли проблеми з розділенням цього платежу.<br>
									Спробуйте відредагувати його із змінею суми та позицій
								</mat-card-title-group>
							}
							@if (checkSumBill && checkAllSumBill) {
								<mat-list>
									@for (user of bill.users; track user) {
										<mat-list-item>
											<div mat-line>
												<div class="usersumshow">
													<div>{{ user.name }}:</div>
													<div>
                            <span class="low-sum">
                              {{ allSum.gSum[user.id] | money }}
								/ {{ allSum.tipSum[user.id] | money }}
                            </span>
														<span class="sum">
                              {{ (allSum.gSum[user.id] || 0) + (allSum.tipSum[user.id] || 0) | money }}
                            </span>
													</div>
												</div>
											</div>
											<mat-progress-bar mat-line
															  class="example-margin"
															  [color]="'primary'"
															  [mode]="'determinate'"
															  [value]="allSum.gSum[user.id] / maxSpendSum * 100"
															  [bufferValue]="75">
											</mat-progress-bar>
										</mat-list-item>
									}
								</mat-list>
							}
						</div>
					</mat-tab>
					<mat-tab>
						<ng-template mat-tab-label>
							<mat-icon title="">{{ iconPayment }}</mat-icon>
							Сплачено
						</ng-template>
						<div (swiperight)="swipe('right')" (swipeleft)="swipe('left')"
							 data-mc-options='{ "touchAction": "pan-y" }'>
							@if (!checkPayments) {
								<div>
									Сума оплат(<b>{{ paymentsSum | money }}</b>)та загальна сума чека
									(<b>{{ bill.allSum || (bill.tip + bill.sum) | money }}</b>) не збігаються
									<mat-icon title=""></mat-icon>
								</div>
							}
							<mat-list>
								@for (sum of payments; track idx; let idx = $index) {
									<div>
										@if (sum) {
											<mat-list-item class="border">
												<div mat-line>
													<div
														style="display: flex; justify-content: space-between; align-items: center">
														<span style="display: inline-flex;">{{ getUserName(idx) }}
															: </span>
														<span style="display: inline-flex;">{{ sum | money }}</span>
													</div>
												</div>
											</mat-list-item>
										}
									</div>
								}
							</mat-list>
						</div>
					</mat-tab>
					<mat-tab>
						<ng-template mat-tab-label>
							<mat-icon title="">
								{{ bill.resolved ? 'compare_arrows' : 'clear' }}
							</mat-icon>
							<span>Борги</span>
						</ng-template>
						<div (swiperight)="swipe('right')" (swipeleft)="swipe('left')"
							 data-mc-options='{ "touchAction": "pan-y" }'>
							<mat-card-title-group>
								{{ getDebtorsTitle() }}
							</mat-card-title-group>
							@if (bill.rights < 1) {
								<mat-list>
									@for (debtObj of debtorsList; track debtObj; let isLast = $last) {
										<mat-list-item
											[ngStyle]="!isLast ? { 'border-bottom': '1px solid rgba(0,0,0,.12)'}: {}"
											style="display: flex; justify-content: space-between; align-items: center">
											<div matListItemTitle class="list-inner-table">
												<span
													class="list-inner-table-first-cell">{{ debtObj.debtorName }}</span>
												<span>
                          <span>{{ debtObj.sum | money }}</span>
                          <mat-icon class="inline-icon">arrow_forward</mat-icon>
                        </span>
												<span class="list-inner-table-last-cell">{{ debtObj.userName }}</span>
											</div>
										</mat-list-item>
									}
								</mat-list>
							} @else {
								<mat-selection-list>
									@for (debtObj of debtorsList; track debtObj; let isLast = $last) {
										<mat-list-item class="border debt-list"
													   [disabled]="creationMOde === 1"
													   (click)="refund(debtObj.debtorId, debtObj.userId, debtObj.sum)">
											<div class="list-inner-table">
												<span
													class="list-inner-table-first-cell">{{ debtObj.debtorName }}</span>
												<span>
                          <span>{{ debtObj.sum | money }}</span>
                          <mat-icon class="inline-icon">arrow_forward</mat-icon>
                        </span>
												<span class="list-inner-table-last-cell">{{ debtObj.userName }}</span>
											</div>
											@if (!isLast) {
												<mat-divider></mat-divider>
											}
										</mat-list-item>
									}
								</mat-selection-list>
							}
						</div>
					</mat-tab>
				</mat-tab-group>
			</mat-card-content>
		</mat-card>
		<mat-card class="float-card">
			<mat-card-content>
				<div class="button-row">
					<mat-radio-group [(ngModel)]="showTable">
						<mat-radio-button [value]="1">Вартість</mat-radio-button> &nbsp; &nbsp;
						<mat-radio-button [value]="0">Пропорції</mat-radio-button>
					</mat-radio-group>
				</div>
				<div style="overflow-x:auto">
					<table mat-table #table [dataSource]="dataSource">
						<ng-container matColumnDef="position">
							<th mat-header-cell
								*matHeaderCellDef> №
							</th>
							<td mat-cell *matCellDef="let i; let index=index;">
								{{ index + 1 }}
							</td>
						</ng-container>
						<!-- Name Column -->
						<ng-container matColumnDef="name">
							<th mat-header-cell class="middle-cell" *matHeaderCellDef>
								Назва
							</th>
							<td mat-cell *matCellDef="let element">
								{{ element.name }}
							</td>
						</ng-container>
						<ng-container matColumnDef="cost">
							<th mat-header-cell *matHeaderCellDef>Варт.</th>
							<td mat-cell *matCellDef="let element">
								{{ element.cost ? (element.cost | money) : null }}
							</td>
						</ng-container>
						<ng-container matColumnDef="quantity">
							<th mat-header-cell *matHeaderCellDef>Кіл.</th>
							<td mat-cell *matCellDef="let element">
								{{ element.quantity ? (element.quantity | qty) : null }}
							</td>
						</ng-container>
						<!-- gSum Column -->
						<ng-container matColumnDef="gSum">
							<th mat-header-cell *matHeaderCellDef>Загалом</th>
							<td mat-cell *matCellDef="let element">
								{{
                                    element.gSum
                                        ? (element.gSum | money)
                                        : null
								}}
							</td>
						</ng-container>

						<!-- Weight Column -->
						@for (user of bill.users; track user) {
							<ng-container matColumnDef="{{'u' + user.id}}">
								<th mat-header-cell *matHeaderCellDef>{{ user.name }}</th>
								<td mat-cell *matCellDef="let element; let idx=index;"
									[matTooltip]="(element.users[user.id] | qty) || ''"
								>
									{{
                                        showTable
                                            ? (allSum.rowsOfCost[idx][user.id] | money:false)
                                            : ((element.users[user.id] | qty) || '')
									}}
								</td>
							</ng-container>
						}
						<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
						<tr mat-row *matRowDef="let cRow; columns: displayedColumns;"
							[ngStyle]="{backgroundColor: cRow.gSum || cRow.cost ? '' : 'beige'}"
						></tr>
					</table>
				</div>
			</mat-card-content>
		</mat-card>
	</div>
}

@if (showError) {
	<div>
    <pre>
      {{ errorMessage | json }}
    </pre>
	</div>
}
