import { Component, OnInit } from '@angular/core';
import {RequesterService} from "../../common/services/requester.service";

@Component({
  selector:    'app-bills',
  templateUrl: './t-bills.component.html',
  styleUrls:   ['./t-bills.component.css']
})
export class tBillsComponent implements OnInit {


  constructor(
    private requester: RequesterService
  ) { }

  ngOnInit() {
    const data = this.requester.getBills();
    // console.log(data);
  }
}
