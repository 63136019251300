import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { AuthService } from '../../common/services/auth.service';
import { MetaInfoService } from '../../common/services/meta-info.service';
import { RequesterService } from '../../common/services/requester.service';

@Component({
	selector:    'app-auth-registration',
	templateUrl: './auth-registration.component.html',
	styleUrls:   ['./auth-registration.component.css'],
})
export class AuthRegistrationComponent implements OnInit {

	form: UntypedFormGroup;
	private title = 'Реєстрація';
	resReg;
	redirectTime = 10;
	interval;
	messageError = '';
	// private submenu = {
	// 	icon: 'cancel',
	// 	href: `/bills/${this.id}`
	// };

	constructor (
		private auth: AuthService,
		private router: Router,
		private metaInfo: MetaInfoService,
		private requester: RequesterService,
	) {
	}

	ngOnInit () {

		this.metaInfo.setTitle('Реєстрація');

		this.form = new UntypedFormGroup({
			email: new UntypedFormControl('', [
				Validators.required,
				Validators.email,
			]),
			password: new UntypedFormControl('', [
				Validators.required,
				Validators.minLength(6),
			]),
			name: new UntypedFormControl('', Validators.required),
		});

		return this.requester.refreshToken()
		.then(() => {

			// юзер залогінений
			return this.router.navigate(['user-info']);
		})
		.catch((err) => {
			// даєм логіниця
		});
	}

	onSubmit () {
		this.metaInfo.showPreloader(true);

		this.auth.registration({
			email:    this.form.value.email,
			password: this.form.value.password,
			name:     this.form.value.name,
		})
		.then((res: any) => {
			this.metaInfo.showPreloader(false);
			if (res.registration) {
				this.resReg = true;
			}
		})
		.catch((e) => {
			this.metaInfo.showPreloader(false);
			if (e.error && e.error.status === 0) {
				this.showError(e.error.data);
			}

			console.log('pre-log-err', e);
		});
	}

	showError ({ message }) {

		this.messageError = message;
		this.interval = setInterval(() => {
			this.handler();
		}, 1000);
	}

	handler () {
		if (this.redirectTime > 1) {
			this.redirectTime = this.redirectTime - 1;
		} else {
			this.messageError = '';
			clearInterval(this.interval);
		}
	}
}
