import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class PostMessageService {

	message: Subject<{ type: string, data: any }>;

	constructor () {

		// console.log('Hello');
		this.message = new Subject();
		const hosts = environment.messageHosts;

		window.addEventListener('message', (event) => {

			if (!hosts.includes(event.origin) || !event.data || !event.data.type) {
				return;
			}

			// console.log('event =-=-=-= susses');
			switch (event.data.type) {

				case 'tokens':
					this.message.next(event.data);
					break;

				case 'preloader':
					this.message.next(event.data);
					break;
			}

		}, false);
	}

	send (type, data) {

		window.parent.postMessage({ type, data }, '*');
	}
}
