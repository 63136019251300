<mat-card class="float-card">
  <mat-card-content>
    <mat-slide-toggle
      class="first-row"
      (click)="toggleShare()"
      [color]="color"
      [checked]="checked"
      [disabled]="disabled">
      Дозволити доступ за посиланням
    </mat-slide-toggle>
  </mat-card-content>
  <mat-card-content #conteiner>
    <mat-form-field class="first-row" style="width: 100%">
      <input matInput placeholder="Посилання на групу" [disabled]="!link" [value]="link" #userinput>
    </mat-form-field>
    <mat-button-toggle-group [disabled]="!link" name="fontStyle" aria-label="Font Style">
      @if (checkShare()) {
        <mat-button-toggle [disabled]="!link" (click)="link ? shareLink() : ''">
          <mat-icon>share</mat-icon>
          {{showTitle ? 'поділитися' : ''}}
        </mat-button-toggle>
      }
      <mat-button-toggle
        #tooltip="matTooltip"  matTooltip="Скопійовано до буфера" matTooltipPosition="above"
        matTooltipHideDelay="3000"
        (click)="link ? copyToBuffer(userinput) : ''">
        <mat-icon>assignment_turned_in</mat-icon>
        {{showTitle ? 'Копіювати' : ''}}
      </mat-button-toggle>
      <mat-button-toggle>
        <a class="center-icon" [ngClass]="{ disableLink: !link }" href="{{link}}" target="_blank">
          <mat-icon class="center-icon">send</mat-icon>
          <span>{{showTitle ? ' Перейти ' : ''}}</span>
        </a>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </mat-card-content>
</mat-card>

@if (collaborators) {
  <mat-card class="float-card text-left">
    <mat-card-content>
      <mat-card-title>Учасники</mat-card-title>
      <mat-list>
        @for (collab of collaborators; track collab) {
          <mat-list-item
            class="border debt-list">
            <div matListItemTitle class="list-inner-table">
              {{ collab.name }}
            </div>
            <span matListItemMeta>
              <mat-form-field>
                <mat-select [value]="'' + collab.rights" [disabled]="blockAdmin && collab.rights > 1 || rights < 1"
                  (selectionChange)="changeRights($event, collab)">
                  <mat-option value="0">Переглядач</mat-option>
                  <mat-option value="1">Редактор</mat-option>
                  @if (collab.rights === 2 || rights > 1) {
                    <mat-option value="2">Адмін</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </span>
          </mat-list-item>
        }
      </mat-list>
    </mat-card-content>
  </mat-card>
}
