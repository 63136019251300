import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

export enum TYPES {
	PAYMENT,
	BILL,
	RETURN,
}

@Injectable()
export class BillService {

	convert (num: any) {
		return Math.round(this.stringToInt(num) * 100);
	}

	convertQ (num: any) {
		return Math.round(this.stringToInt(num) * 100);
	}

	decodeMoney (num: number) {
		return this.stringToInt(num) / 100;
	}

	decodeQty (num: number) {
		return this.stringToInt(num) / 100;
	}

	convertDateToFront (date: string) {
		if (!date) {
			return null;
		}

		const isoDate = DateTime.fromISO(date);
		if (isoDate.isValid) {
			return  isoDate.toFormat('yyyy-MM-dd HH:mm');
		}

		const dteSql = DateTime.fromSQL(date);
		if (dteSql.isValid) {
			return  dteSql.toFormat('yyyy-MM-dd HH:mm');
		}

		return date;
	}

	convertToFront (data) {
		return {
			id:       +data.id,
			rights:   +data.rights,
			name:     data.name,
			sum:      +data.billSum,
			tip:      +data.tipSum,
			allSum:   +data.allSum,
			fill:     data.fill === null ? null : +data.fill,
			date:     this.convertDateToFront(data.date),
			desc:     data.desc,
			resolved: data.resolved,
			type:     data.type === 'BILL' ? TYPES.BILL : TYPES.PAYMENT,
			users:    this.convertUsersToFront(data.users || []),
			rows:     this.convertRowsToFront(data.rows || []),
			payments: this.convertPaymentsToFront(data.payments || []),
			userTips: this.convertPaymentsToFront(data.userTips || { }),
			debtors:  this.convertDebtorsToFront(data.debtors || { })
		};
	}

	convertPaymentToFront (data) {
		return {
			id:        +data.id,
			rights:    +data.rights,
			name:      data.name,
			sum:       +data.sum,
			tip:       +data.tip,
			allSum:    +data.allSum,
			fill:      data.fill === null ? null : +data.fill,
			date:      data.date,
			desc:      data.desc,
			resolved:  data.resolved,
			type:      data.type === 'BILL' ? TYPES.BILL : TYPES.PAYMENT,
		};
	}

	convertPaymentToFrontOld (data) {
		return {
			id:       +data.id,
			rights:   +data.rights,
			name:     data.name,
			sum:      +data.sum,
			tip:      +data.tip,
			allSum:   +data.allSum,
			fill:     data.fill === null ? null : +data.fill,
			date:     data.date,
			desc:     data.desc,
			resolved: data.resolved,
			type:     data.type === 'BILL' ? TYPES.BILL : TYPES.PAYMENT,
		};
	}

	convertBill (data: any): any {
		return {
			id:       data.id === undefined ? data.id : +data.id,
			name:     data.name,
			sum:      this.convert(data.sum),
			tip:      data.allSum === null
				? this.convert(data.tip) || null : this.cGetTips(data.allSum, data.sum),
			allSum:   data.tip === null
				? this.convert(data.allSum) || null : this.cGetAllSum(data.sum, data.tip),
			fill:     (this.convert(data.allSum) === 0 && this.convert(data.tip) === 0)
				? null : ((data.tip === '' || data.tip === null || data.tip === 0) ? 0 : 1),
			date:     data.date,
			dateTime: Date.now(),
			desc:     data.desc,
			type:     data.type,
		};
	}

	convertToForm (data): any {
		return {
			id:     data.id,
			name:   data.name,
			sum:    this.decodeMoney(data.sum),
			tip:    data.fill === 1 ? this.decodeMoney(data.tip) : null,
			allSum: data.fill === 0 ? this.decodeMoney(data.allSum) : null,
			fill:   data.fill,
			date:   data.date,
			desc:   data.desc,
			type:   data.type === 'BILL' ? TYPES.BILL : TYPES.PAYMENT,
		};
	}

	convertPayment (data: any) {
		return {
			id:         data.id === undefined ? data.id : +data.id,
			name:       data.name,
			sum:        data.sum,
			date:       data.date,
			dateTime:   Date.now(),
			desc:       data.desc,
		};
	}

	convertPaymentsToForm (payments) {
		return payments.map(payment => {
			return this.decodeMoney(payment);
		});
	}

	convertRowToForm (row) {

		const data = {
			id:       row.id,
			name:     row.name,
			users:    row.users.map(u => {
				u = u ? u / 100 : null;

				return u === u ? u : null;
			}),
			quantity: row.quantity == null ? null : this.decodeQty(row.quantity),
			cost:     row.cost == null ? null : this.decodeMoney(row.cost),
			gSum:     row.gSum == null ? null : this.decodeMoney(row.gSum),
		};

		if (row.resolve === 'gSum') {
			data.gSum = null;
		} else if (row.resolve === 'cost') {
			data.cost = null;
		} else if (row.resolve === 'quantity') {
			data.quantity = null;
		}

		return data;
	}

	// convertToBack(data) {
	//
	// 	return {
	// 		id: data.id === undefined ? data.id : +data.id,
	// 		name: data.name,
	// 		billSum: data.sum,
	// 		tipSum: data.allSum === null ? +data.tip || null : this.getTips(data),
	// 		allSum: data.tip === null ? +data.allSum || null : this.getAllSum(data),
	// 		fill: (data.allSum === null && data.tip === null) ? null : (data.tip === null ? 0 : 1),
	// 		date: this.getDateTime(data.date),
	// 		editTime: Date.now(),
	// 	};
	// }

	convertRowsToBack (list) {

		return list.map(row => {

			return {
				id:       +row.id,
				name:     row.name,
				cost:     this.stringToInt(row.cost) || null,
				quantity: this.stringToInt(row.quantity) || null,
				gSum:     this.stringToInt(row.gSum) || null,
				resolve:  row.resolve || null,
				users:    (row.users || []).map(item => {
					return Math.round(this.stringToInt(item));
				})
			};
		});
	}

	convertUsersToFront (users) {
		return users.map(user => {
			return {
				id:   +user.id,
				name: user.name
			};
		});
	}

	convertRowsToFront (list) {

		return list.map(row => {
			return {
				id:       +row.id,
				name:     row.name,
				cost:     row.cost ? this.stringToInt(row.cost) : null,
				quantity: row.quantity ? this.stringToInt(row.quantity) : null,
				gSum:     row.gSum ? this.stringToInt(row.gSum) : null,
				resolve:  row.resolve || null,
				users:    (row.users || []).map(item => {
					return item ? this.stringToInt(item) : 0;
				}),
				amounts: (row.amounts || []).map(item => {
					return item ? this.stringToInt(item) : 0;
				})
			};
		});
	}

	convertPaymentsToFront (list) {

		return list.map(row => {
			return row == null ? null : this.stringToInt(row);
		});
	}

	convertDebtorsToFront (debtors) {

		const debObj = { };

		Object.keys(debtors).forEach(userId => {

			debObj[userId] = {
				sum:     debtors[userId].sum || 0,
				debtors: { }
			};

			Object.keys(debtors[userId].debtors).forEach(debtorId => {

				debObj[userId].debtors[debtorId] = { };
				debObj[userId].debtors[debtorId].sum = this.stringToInt(debtors[userId].debtors[debtorId].sum);
			});
		});

		return debObj;
	}

	getClearTips (data) {
		return (+data.sum || 0) * 0.1;
	}

	cGetClearTips (num: any) {
		return this.convert(num) * 0.1;
	}

	getTips (data) {
		return +data.allSum - +data.sum || 0;
	}

	cGetTips (allSum, checkSum) {
		return this.convert(allSum) - this.convert(checkSum);
	}

	getClearAllSum (data) {
		return (+data.sum || 0) * 11 / 10;
	}

	cGetClearAllSum (sum) {
		return this.convert(sum) * 11 / 10;
	}

	getAllSum (data) {
		return +data.sum + +data.tip || 0;
	}

	cGetAllSum (sum, tip) {
		return this.convert(sum) + this.convert(tip);
	}

	stringToInt (str: any) {
		const data = str
			? Math.round(parseFloat(String(str).replace(',', '.')) * 100) / 100
			: 0;

		return data === data ? data : 0;
	}

	cGetCost (gSum, quantity) {
		const res = this.convert(gSum) / this.stringToInt(quantity) * 100;

		return res === res && isFinite(res) ? res / 100 : null;
	}

	cGetQuantity (gSum, cost) {
		const res = this.convert(gSum) / this.convert(cost);

		return res === res && isFinite(res) ? this.convertQ(res) : null;
	}

	cGetGSum (cost, quantity) {
		const res = this.convert(cost) * this.stringToInt(quantity);

		return res === res && isFinite(res) ? res : null;
	}

	showAllSum (bill) {
		return bill.allSum ? bill.allSum : ((bill.sum ? +bill.sum : 0) + (bill.tip ? bill.tip : 0)) || 0;
	}

	validateProcess (rowObj) {

		// console.log('row ===', rowObj);

		const validObj: any = { };

		validObj.id = rowObj.id === undefined ? undefined : this.stringToInt(rowObj.id);
		validObj.name = rowObj.name;

		let tmpCost = this.convert(rowObj.cost);
		if (tmpCost) {
			validObj.cost = tmpCost;
		} else {
			tmpCost = this.cGetCost(rowObj.gSum, rowObj.quantity);
			if (tmpCost) {
				validObj.resolve = 'cost';
				validObj.cost = tmpCost;
			}
		}

		let tmpQuantity = this.convertQ(rowObj.quantity);
		if (tmpQuantity) {
			validObj.quantity = tmpQuantity;
		} else {
			tmpQuantity = this.cGetQuantity(rowObj.gSum, rowObj.cost);
			if (tmpQuantity) {
				validObj.resolve = 'quantity';
				validObj.quantity = tmpQuantity;
			}
		}

		let tmpGSum = this.convert(rowObj.gSum);
		if (tmpGSum) {
			validObj.gSum = tmpGSum;
		} else {
			tmpGSum = this.cGetGSum(rowObj.cost, rowObj.quantity);
			if (tmpGSum) {
				validObj.resolve = 'gSum';
				validObj.gSum = tmpGSum;
			}
		}

		validObj.users = (Array.isArray(rowObj.users)
			? rowObj.users : []).map(item => this.stringToInt(item) * 100);

		return validObj;
	}
}
