import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { DialogComponent } from '../../common/components/dialog/dialog.component';
import { MetaInfoService } from '../../common/services/meta-info.service';
import { RequesterService } from '../../common/services/requester.service';

@Component({
	selector:    'app-start-page',
	templateUrl: './start-page.component.html',
	styleUrls:   ['./start-page.component.css']
})
export class StartPageComponent implements OnInit {

	showDialog: boolean;
	version;
	bacVersion;
	text = '';

	constructor (
		public metaInfo: MetaInfoService,
		private requester: RequesterService,
		private dialog: MatDialog,
	) {
	}

	async ngOnInit () {
		this.version = environment.version;
		try {
			this.metaInfo.setData({
				rightMenu: {},
				title:     'Про додаток'
			});

			this.bacVersion = await this.requester.gevVersion();
		} catch (e) {
			this.metaInfo.showError(e);
		}
	}

	checkPass (pass: string) {
		let text = 'Ты не знаешь код!';

		if (/32167/.test(pass)) {
			text = 'НІ, ЧОРНИХ ДРАКОНІВ ВЖЕ НЕ ЗАЛИШИЛОСЯ!!!';
		}

		const dialogRef = this.dialog.open(DialogComponent, {
			width: '450px',
			data:  {
				title:         text,
				rightBtnTitle: 'Ну і добре',
				leftBtnTitle:  'Ага',
			}
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result && result.type === 'rightClick') {

			}
		});
	}

	// async test () {
	// 	 await this.requester.gevVersion();
	// 	 await this.requester.getBills();
	// 	 await this.requester.getlogin();
	// 	// const data = await this.requester.login({
	// 	// 	hash: '=-=-=-=-',
	// 	// 	localHash: '=-=--=-',
	// 	// 	allHash: '=-=-=-=',
	// 	// 	extra: '',
	// 	// }, {
	// 	// 	withCredentials: true
	// 	// });
	// }
}
