import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SystemError } from '../Error';

import { environment } from '../../../environments/environment';
import { BillService } from './bill.service';
import { StorageService } from './storage.service';

@Injectable()
export class RequesterService {

	private baseURL = environment.apiUrl;  // URL to web api
	showPreloader = false;

	constructor (
		private http: HttpClient,
		private bill: BillService,
		private storage: StorageService,
	) {
	}

	getHeaders (needToken = false): Promise<{ withCredentials: boolean }> {
		return Promise.resolve({ withCredentials: true });
	}

	getToken (): Promise<any> {
		return Promise.all([
			this.storage.get('token'),
			this.storage.get('tokenExp'),
		])
		.then(([token, expTime]) => {

			if (+expTime > (Date.now() + 10000) && token) {
				return { token };
			}

			throw new Error('Does not have token');
		});
	}

	async refreshToken () {
		return this._post(`/auth/refresh`, null, false);
	}

	gevVersion () {
		return this.request('get', `/version`, { });
	}

   	/* ******************************* Payments *****************************/

	async createPayment (payment) {
		const headers = await this.getHeaders(true);

		return this.request('post', `/payments`, payment, headers);
	}

	async editPayment (id, payment) {
		const headers = await this.getHeaders(true);

		return this.request('put', `/payments/${id}`, payment, headers);
	}

	getBills (): Promise<Array<object>> {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('get', '/bills', opt);
		});
	}

	showBillShareLink (billId) {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('get', `/payments/${billId}/share`, opt);
		});
	}

	createBillShareLink (billId) {
		return this.getHeaders(true)
		.then(opt => {

			// console.log('=-=- wtf', opt);
			return this.request('post', `/bills/${billId}/share`, { }, opt);
		});
	}

	delBillShareLink (billId) {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('delete', `/bills/${billId}/share`, opt);
		});
	}

	addSharedBill (shareId): Promise<{ billNumber: number }> {

		return this.getHeaders(true)
		.then(opt => {
			return this.request('post', `/share/${shareId}/bill`, { }, opt);
		});
	}

	getShareBill (url) {
		return this.getHeaders()
		.then(opt => {
			return this.request('get', `/share/${url}/bill`, opt)
			.then((data: any) => {

				let fullBill: any;
				fullBill = this.bill.convertToFront(data);

				return fullBill;
			});
		});
	}

	getFullBill (id): Promise<any> {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('get', '/bills/' + id + '/full', opt)
			.then((data: any) => {
				let fullBill: any;
				fullBill = this.bill.convertToFront(data);

				return fullBill;
			});
		});
	}

	getBill (id) {
		return this.getHeaders(true)
		.then(headers => {

			return this.request('get', `/bills/${id}`, headers);
		})
		.then(data => {
			return this.bill.convertToFront(data);
		});
	}

	createBill (bill) {
		return this.getHeaders(true)
		.then(headers => {

			return this.request('post', `/bills`, bill, headers);
		});
	}

	editBill (id, bill) {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('put', `/bills/${ id }`, bill, opt);
		});
	}

	deleteBill (id) {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('delete', `/bills/${ id }`, opt);
		});
	}

	// : Promise<Array<any>>
	saveBillRows (id, rows) {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('post', `/bills/${ id }/rows`, rows, opt);
		});

	}

	// ): Promise<Array<any>>

	editBillRows (id, rows) {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('put', `/bills/${ id }/rows`, rows, opt);
		});
	}

	editBillDetails (id, data) {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('put', `/bills/${ id }/details`, data, opt);
		});
	}

	editBillPayments (id, payments) {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('put', `/bills/${ id }/payments`, payments, opt);
		});
	}

	// todo тупо теж що і знизу
	saveBillUsers (id, data) {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('put', `/bills/${ id }/users`, data, opt);
		});
	}

	editBillUsers (id, data) {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('put', `/bills/${ id }/users`, data, opt);
		});
	}

	preAuth (data) {
		return this.request('post', `/auth/pre-auth`, data);
	}

	login (data, ops) {
		return this.request('post', '/auth/login', data, ops);
	}

	async logout () {
		return this._post('/auth/logout', null, false);
	}

	loginWithSocial (data: { name: string, email: string, photoUr: string, token: string, provider: string }) {
		return this.request('post', '/auth/login/social', data);
	}

	registration (data) {
		return this.request('post', '/auth/registration', data);
	}

	confirmEmail (data) {
		return this.request('post', '/auth/confirm/email', data);
	}

	resetPass (email) {
		return this.request('post', '/auth/reset-pass', { email });
	}

	newPass (data) {
		return this.request('post', '/auth/new-pass', data);
	}

	/**  GROUP */

	createGroup (data) {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('post', `/groups`, data, opt);
		});
	}

	editGroup (data) {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('post', `/groups`, data, opt);
		});
	}

	updateGroup (id, data) {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('put', `/groups/${id}`, data, opt);
		});
	}

	createGroupMembers (id, data) {
		return this.updateGroup(id, data);
	}

	getGroups (): Promise<Array<object>> {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('get', `/groups`, opt);
		});
	}

	getGroup (id): Promise<{ name: string, currency: string, members: Array<{ id: number, name: string }> }> {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('get', `/groups/${id}`, opt);
		});
	}

	getFullGroup (id) {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('get', `/groups/${id}/full`, opt);
		});
	}

	getGroupMembers (id) {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('get', `/groups/${id}/members`, opt);
		});
	}

	createGroupBill (groupId, data) {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('post', `/groups/${groupId}/bills`, data, opt);
		});
	}

	createGroupPayment (groupId, data) {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('post', `/groups/${groupId}/payments`, data, opt);
		});
	}

	editGroupPayment (groupId, paymentId, data) {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('put', `/groups/${groupId}/payments/${paymentId}`, data, opt);
		});
	}

	getGroupBillFull (groupId, billId) {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('get', `/groups/${groupId}/bills/${billId}/full`, opt)
			.then((data: any) => {

				let fullBill: any;
				fullBill = this.bill.convertToFront(data);

				return fullBill;
			});
		});
	}

	getShareGroupBillFull (groupUrl, billId) {
		return this.getHeaders()
		.then(opt => {
			return this.request('get', `/share/${groupUrl}/group/bills/${billId}/full`, opt)
			.then((data: any) => {

				let fullBill: any;
				fullBill = this.bill.convertToFront(data);

				return fullBill;
			});
		});
	}

	saveGroupBillRows (groupId, billId, rows) {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('post', `/groups/${ groupId }/bills/${ billId }/rows`, rows, opt);
		});
	}

	getGroupBill (groupId, billId) {
		return this.getHeaders(true)
		.then(headers => {
			return this.request('get', `/groups/${ groupId }/bills/${ billId }`, headers)
			.then(data => {
				return this.bill.convertToFront(data);
			});
		});
	}

	editGroupBill (groupId, billId, bill) {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('put', `/groups/${ groupId }/bills/${ billId }`, bill, opt);
		});
	}

	editGroupBillRows (groupId, billId, rows) {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('put', `/groups/${ groupId }/bills/${ billId }/rows`, rows, opt);
		});
	}

	editGroupBillDetails (groupId, billId, data) {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('put', `/groups/${ groupId }/bills/${ billId }/details`, data, opt);
		});
	}

	editGroupBillPayments (groupId, billId, payments) {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('put', `/groups/${ groupId }/bills/${ billId }/payments`, payments, opt);
		});
	}

	editGroupBillUsers (groupId, billId, data) {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('put', `/groups/${ groupId }/bills/${ billId }/users`, data, opt);
		});
	}

	deleteGroupBill (groupId, billId) {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('delete', `/groups/${ groupId }/bills/${ billId }`, opt);
		});
	}

	deleteGroup (groupId) {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('delete', `/groups/${ groupId }`, opt);
		});
	}

	showGroupShareLink (groupId) {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('get', `/groups/${groupId}/share`, opt);
		});
	}

	createGroupShareLink (shareId): Promise<{ billNumber: number }> {

		return this.getHeaders(true)
		.then(opt => {
			return this.request('post', `/groups/${shareId}/share`, { }, opt);
		});
	}

	delGroupShareLink (billId) {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('delete', `/groups/${billId}/share`, opt);
		});
	}

	changeGroupRights (groupId, userId, rights) {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('put', `/groups/${groupId}/change-rights`, { userId, rights }, opt);
		});
	}

	changePaymentRights (paymentId, userId, rights) {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('put', `/payments/${paymentId}/change-rights`, { userId, rights }, opt);
		});
	}

	addSharedGroup (shareId): Promise<{ groupNumber: number }> {

		return this.getHeaders(true)
		.then(opt => {
			return this.request('post', `/share/${shareId}/group`, { }, opt);
		});
	}

	getSharedGroup (shareUrl) {
		return this.getHeaders()
		.then(opt => {
			return this.request('get', `/share/${shareUrl}/group`, opt);
		});
	}

	/**  USER */

	getUser (): Promise<{ name: string, email: string, notification: boolean }> {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('get', `/user`, opt);
		});
	}

	updateUser (data): Promise<any> {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('put', `/user`, data, opt);
		});
	}

	postUserSub (userId, sub): Promise<void> {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('post', `/user/${userId}/subscription`, sub, opt);
		});
	}

	delUserSub (sub): Promise<void> {
		return this.getHeaders(true)
		.then(opt => {
			const simpleSub = JSON.parse(JSON.stringify(sub));

			// console.log('=-=-=-simple', simpleSub);

			return this.request('delete', `/user/subscription/${simpleSub.keys.auth}`, opt);
		});
	}

	refundBill (billId, data) {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('post', `/bills/${billId}/refund`, data, opt);
		});
	}

	refundGroup (groupId, data) {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('post', `/groups/${groupId}/refund`, data, opt);
		});
	}

	refundGroupEdit (groupId, refundId, data) {
		return this.getHeaders(true)
		.then(opt => {
			return this.request('put', `/groups/${groupId}/refunds/${refundId}`, data, opt);
		});
	}

	getTest (): Promise<any> {
		return this.getHeaders()
		.then(opt => {
			return this.request('get', `/test/1`, opt);
		});
	}

	_get (url: string, ignoreSW?: boolean | string, timeout?): Promise<any> {
		const opt = { withCredentials: true };
		if (ignoreSW && environment.production) {
			opt['headers'] = {
				'ngsw-bypass': 'true'
			};
		}
		// it is only a custom header, so we need find something else
		// if (timeout) {
		// 	opt['headers'] = {
		// 		'timeout': `${timeout}`
		// 	};
		// }

		this.http.get('http://localhost:8080', {
			headers: {}
		});

		return this.request('get', url, opt);
	}

	_del (url: string) {
		const opt = { withCredentials: true };

		return this.request('delete', url, opt);
	}

	_post (url: string, data?, relogin = true) {
		const opt = { withCredentials: true };

		return this.newRequester('post', url, data, opt, relogin);
	}

	_patch (url: string, data?, relogin = true) {
		const opt = { withCredentials: true };

		return this.newRequester('patch', url, data, opt, relogin);
	}

	async request (method, url, second?, third?): Promise<any> {
		try {
			return await this._request(method, url, second, third);
		} catch (err) {
			if (err?.code === 'NEED_AUTH') {
				await this.refreshToken();

				return this._request(method, url, second, third);
			}

			throw err;
		}
	}

	async newRequester (method: string, url: string, ...rest) {
		const relogin = rest.pop();
		try {
			return await this._request(method, url, ...rest);
		} catch (err) {
			if (relogin && err?.code === 'NEED_AUTH') {
				await this.refreshToken();

				return this._request(method, url, ...rest);
			}

			throw err;
		}
	}

	_request (method, url, second?, third?): Promise<any> {
		return new Promise((res, rej) => {
			return this.http[method](`${this.baseURL}${url}`, second, third)
				.subscribe((response: any) => {
					if (response.status) {
						return res(response.data);
					}

					return rej(response);
				}, (err: any) => {
					if (err.status === 401) {
						return rej(new SystemError({
							code:   'NEED_AUTH',
							status: err.status,
						}));
					}

					if (err.status >= 500) {
						return rej(new SystemError({
							code:   'SERVER_ERROR',
							status: err.status,
						}));
					}

					if (err.status === 0) {
						return rej(new SystemError({
							code:          'CONNECTION_ERROR',
							status:        err.status,
							originalError: err,
						}));
					}

					return rej(err);
				});
		});
	}

// /**
//  * Handle Http operation that failed.
//  * Let the system continue.
//  * @param operation - name of the operation that failed
//  * @param result - optional value to return as the observable result
//  */
// private handleError (operation = 'operation', result?) {
//   return (error: any): Observable => {
//
//     // TODO: send the error to remote logging infrastructure
//     console.error(error); // log to console instead
//
//     return of(result);
//   };
// }
}
