import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { RequesterService } from '../../common/services/requester.service';
import { MetaInfoService } from '../../common/services/meta-info.service';
import { AuthService } from '../../common/services/auth.service';

enum mode {
	SINGLE,
	GROUP,
}

@Component({
	selector:    'app-group-delete',
	templateUrl: './group-delete.component.html',
	styleUrls:   ['./group-delete.component.css'],
})
export class GroupDeleteComponent implements OnInit {

	disabledButton = true;
	disabled;
	color;
	checked;
	private groupId = this.currentRout.snapshot.params.groupId;


	creationMOde = 0;

	private CONFIG = [{
		methodShow: 'deleteGroup',
		navigate:   ['groups', 'list'],
		params:     [this.groupId],
		url:        `/groups/${this.groupId}`,
	},
	];

	private title = 'Видалити рахунок';
	private submenu = {
		icon: 'cancel',
		href: this.CONFIG[this.creationMOde].url,
	};

	constructor (
		private auth: AuthService,
		private requester: RequesterService,
		private router: Router,
		private currentRout: ActivatedRoute,
		private metaInfo: MetaInfoService,
	) {
	}

	ngOnInit () {

		this.metaInfo.setData({
			rightMenu: this.submenu,
			title:     'Видалення групи',
		});
	}

	toggleDelete () {
		this.disabledButton = !this.disabledButton;
	}

	deleteGroup () {
		this.metaInfo.showPreloader(true);

		this.requester[this.CONFIG[this.creationMOde].methodShow](...this.CONFIG[this.creationMOde].params)
		.then(() => {
			this.metaInfo.showPreloader(false);

			this.router.navigate(this.CONFIG[this.creationMOde].navigate);
		})
		.catch(err => {
			this.metaInfo.showError(err);
		});
	}
}


