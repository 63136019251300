import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '../../common/services/auth.service';
import { MetaInfoService } from '../../common/services/meta-info.service';
import { RequesterService } from '../../common/services/requester.service';

@Component({
	selector:    'app-auth-password-reset',
	templateUrl: './auth-password-reset.component.html',
	styleUrls:   ['./auth-password-reset.component.css'],
})
export class AuthPasswordResetComponent implements OnInit {

	form: UntypedFormGroup;
	showFinish = false;

	constructor (
		private auth: AuthService,
		private metaInfo: MetaInfoService,
		private requester: RequesterService,
		private router: Router,
	) {
	}

	ngOnInit () {

		this.metaInfo.setTitle('Скидання паролю');

		this.form = new UntypedFormGroup({
			email: new UntypedFormControl('', [
				Validators.required,
				Validators.email,
			]),
		});

		return this.requester.refreshToken()
		.then(() => {

			// юзер залогінений
			return this.router.navigate(['user-info']);
		})
		.catch((err) => {
			// даєм логіниця
		});
	}

	onSubmit () {
		this.metaInfo.showPreloader(true);

		this.auth.resetPass(this.form.value.email)
		.then(() => {
			this.metaInfo.showPreloader(false);
			this.showFinish = true;
		})
		.catch((e) => {
			this.metaInfo.showPreloader(false);
			console.log('reset-log-err', e);
		});
	}
}
