import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { RequesterService } from '../../common/services/requester.service';
import { MetaInfoService } from '../../common/services/meta-info.service';

@Component({
	selector:    'app-auth-confirm-email',
	templateUrl: './auth-confirm-email.component.html',
	styleUrls:   ['./auth-confirm-email.component.css'],
})
export class AuthConfirmEmailComponent implements OnInit {

	result;
	interval;
	redirectTime = 5;

	constructor (
		private requester: RequesterService,
		private router: Router,
		private currentRout: ActivatedRoute,
		private metaInfo: MetaInfoService,
	) {
	}

	ngOnInit () {

		this.metaInfo.showPreloader(true);

		this.requester.confirmEmail({
			code: this.currentRout.snapshot.params.salt,
		})
		.then((data) => {
			this.metaInfo.showPreloader(false);
			this.result = {
				type:    1,
				message: 'Вы удачно подтвердили email', // You successfully confirmed email address
			};

			this.interval = setInterval(() => {
				this.handler();
			}, 1000);
		})
		.catch((err) => {
			// todo improve show error
			this.metaInfo.showPreloader(false);
			this.result = {
				type:    0,
				message: 'Щось пішло не так, посилання не дійсне.',
			};
		});
	}

	handler () {
		if (this.redirectTime > 1) {
			this.redirectTime = this.redirectTime - 1;
		} else {
			clearInterval(this.interval);
			this.router.navigate(['login']);
		}
	}
}
