import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class MetaInfoService {

	private titleSubject: BehaviorSubject<string> = new BehaviorSubject('load...');
	title$: Observable<string> = this.titleSubject.asObservable();

	private rightMenuSubject: BehaviorSubject<Array<any> | object> = new BehaviorSubject(null);
	rightMenu$: Observable<Array<any> | object> = this.rightMenuSubject.asObservable();

	private leftMenuSubject: BehaviorSubject<Array<any> | object> = new BehaviorSubject(null);
	leftMenu$: Observable<Array<any> | object> = this.leftMenuSubject.asObservable();

	private errorSubject: BehaviorSubject<object> = new BehaviorSubject(null);
	error$: Observable<object> = this.errorSubject.asObservable();

	private showPreloaderSubject: BehaviorSubject<object|boolean> = new BehaviorSubject(false);
	showPreloader$: Observable<object|boolean> = this.showPreloaderSubject.asObservable();

	setData (data: { title?: string, leftMenu?: { }, rightMenu?: { } }) {
		setTimeout(() => {
			this.setTitle(data.title);
			this.rightMenuSubject.next(data.rightMenu);
			this.leftMenuSubject.next(data.leftMenu);
		}, 0);
	}

	setTitle (title) {
		setTimeout(() => {
			this.titleSubject.next(title);
		});
	}

	async showPreloader (doShow: boolean, newTitle?: string) {
		return new Promise(resolve => {
			setTimeout(() => {
				if (doShow) {
					this.setTitle(newTitle || 'Loading...');
				} else {
					this.setTitle(newTitle);
				}

				this.showPreloaderSubject.next(doShow);
				resolve(null);
			},         0);
		});
	}

	showError (error) {
		this.showPreloader(false);

		setTimeout(() => {
			this.errorSubject.next(error);
		});
	}
}
