<mat-card class="float-card text-left">
  <mat-card-content>
    @if (groupsList && groupsList.length) {
      <mat-selection-list >
        @for (group of groupsList; track group) {
          <mat-list-item
            class="border list-slim"
            [ngClass]="group.resolved ? '' : 'app-warning'"
            routerLink="/groups/{{group.id}}">
            <mat-icon matListItemIcon>folder_open</mat-icon>
            <h3 matListItemTitle>
              {{group.name}}
            </h3>
            <p matListItemLine style="margin-bottom: 0; text-align: center">
              {{hs.formatDateTime(group.date)}}
            </p>
            <span matListItemMeta>
              <mat-icon class="icon-vert-align">{{ group.link ? 'share' : '' }}</mat-icon>
              <span style="vertical-align: 5px; color: red; font-size: 18px; font-weight: bolder;">{{ group.currency }}</span>
            </span>
          </mat-list-item>
        }
      </mat-selection-list>
    } @else {
      <mat-card-header>
        <mat-card-title>У вас все ще немає жодної групи</mat-card-title>
      </mat-card-header>
    }
  </mat-card-content>
</mat-card>
<button class="fixed-action-btn" routerLink="/groups/create" mat-fab color="primary"
  aria-label="Додати групу">
  <mat-icon>create_new_folder</mat-icon>
</button>
