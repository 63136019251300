import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../../common/services/auth.service';
import { BillService } from '../../common/services/bill.service';
import { HelperService } from '../../common/services/helper.service';
import { MetaInfoService } from '../../common/services/meta-info.service';
import { RequesterService } from '../../common/services/requester.service';

enum mode {
	SINGLE_SHOW,
	SINGLE_CREAT,
	GROUP_SHOW,
	GROUP_CREAT,
	GROUP_EDIT,
}

@Component({
	selector:    'app-refund',
	templateUrl: './refund.component.html',
	styleUrls:   ['./refund.component.css'],
})
export class RefundComponent implements OnInit {

	userFrom: { id?: number, name?: string } = { };
	userTo: { id?: number, name?: string } = { };
	users: Array<{ id?: number, name?: string }> = [];
	amount;
	date = '';
	name;
	id = this.currentRout.snapshot.params.id;
	billId = this.currentRout.snapshot.params.billId;
	groupId = this.currentRout.snapshot.params.groupId;
	refundId = this.currentRout.snapshot.params.refundId;
	refundType = this.currentRout.snapshot?.data?.type;
	config;
	rights;
	currentType;
	editable;

	private CONFIG;

	private title = 'Повернення коштів';

	constructor (
		private auth: AuthService,
		private requester: RequesterService,
		private router: Router,
		private currentRout: ActivatedRoute,
		private metaInfo: MetaInfoService,
		private bs: BillService,
		public  hs: HelperService,
	) {
		switch (this.refundType) {
			case 'groupCreate':
				this.CONFIG = {
					type:      mode.GROUP_CREAT,
					rightMenu: {
						icon: 'cancel',
						href: this.billId ? `/groups/${this.groupId}/bills/${this.billId}` : `/groups/${this.groupId}`,
					},
					navigate:   ['groups', this.groupId],
					requestGet: 'getFullGroup',
					paramsGet:  [this.groupId],
					requestSet: 'refundGroup',
					paramsSet:  this.groupId,
				};
				this.editable = true;
				break;
			case 'groupShow':
				this.CONFIG = {
					type:     mode.GROUP_SHOW,
					leftMenu: {
						icon: 'keyboard_backspace',
						href: `/groups/${this.groupId}`
					},
					rightMenu: [{
						title: 'Редагувати',
						icon:  'cancel',
						href:  `/groups/${this.groupId}/refunds/${this.refundId}/edit`,
					},          {
						title: 'Видалити',
						icon:  'delete',
						href:  `/groups/${this.groupId}/refunds/${this.refundId}/delete`,
					}],
					navigate:   ['groups', this.groupId],
					requestGet: 'getGroupBillFull',
					paramsGet:  [this.groupId, this.refundId],
					requestSet: 'refundGroup',
					paramsSet:  this.groupId,
				};
				break;
			case 'groupEdit':
				this.CONFIG = {
					type:      mode.GROUP_EDIT,
					rightMenu: {
						icon: 'cancel',
						href: `/groups/${this.groupId}/refunds/${this.refundId}`,
					},
					navigate:   ['groups', this.groupId],
					requestGet: 'getGroupBillFull',
					paramsGet:  [this.groupId, this.refundId],
					requestSet: 'refundGroupEdit',
					paramsSet:  this.groupId,
				};
				this.editable = true;
				break;
			case 'singleCreate':
				this.CONFIG = {
					type:      mode.SINGLE_CREAT,
					rightMenu: {
						icon: 'cancel',
						href: `/bills/${this.id}`,
					},
					navigate:   ['bills', this.id],
					requestGet: 'getFullBill',
					paramsGet:  [this.id],
					requestSet: 'refundBill',
					paramsSet:  this.id,
				};
				this.editable = true;
				break;
			default:
				this.CONFIG = {
					type:     mode.SINGLE_SHOW,
					leftMenu: {
						icon: 'keyboard_backspace',
						href: `/groups/${this.groupId}`
					},
					navigate:   ['groups', this.groupId],
					requestGet: 'getGroupBillFull',
					paramsGet:  [this.groupId, this.refundId],
					requestSet: 'refundBill',
					paramsSet:  this.refundId,
				};
		}
	}

	async ngOnInit () {
		this.metaInfo.showPreloader(true);

		try {
			const data = await this.requester[this.CONFIG.requestGet](...this.CONFIG.paramsGet);
			this.metaInfo.showPreloader(false);
			this.metaInfo.setData({
				title:     this.title,
				rightMenu: data.rights ? this.CONFIG.rightMenu : null,
				leftMenu:  this.CONFIG.leftMenu,
			});

			this.users = data.users || data.members;
			this.rights = data.rights;

			this.date = data.date ? this.hs.formatDateTime(data.date) : this.hs.getDateTime();

			if ([mode.GROUP_EDIT, mode.GROUP_SHOW, mode.GROUP_CREAT].includes(this.CONFIG.type)) {
				if (mode.GROUP_CREAT === this.CONFIG.type) {
					this.amount = this.bs.decodeMoney(this.currentRout.snapshot.params.amount);

					this.userFrom = (this.users).find(user => {
						return user.id === +this.currentRout.snapshot.params.userFrom;
					}) || { };

					this.userTo = (this.users).find(user => {
						return user.id === +this.currentRout.snapshot.params.userTo;
					}) || { };

					this.date = this.hs.getDateTime();
				} else {
					this.name = data.name;
					this.amount = this.bs.decodeMoney(data.allSum);
					const debEntries = Object.entries(data.debtors);

					if (debEntries.length) {
						const [[userFromId, { debtors }]]: any = debEntries;
						const [[userToId]] = Object.entries(debtors);

						this.userFrom = (this.users).find(user => {
							return user.id === +userFromId;
						}) || { };
						this.userTo = (this.users).find(user => {
							return user.id === +userToId;
						}) || { };
					}

					this.date = data.date ? this.hs.formatDateTime(data.date) : this.hs.getDateTime();
				}
			} else {
				this.userFrom = (this.users).find(user => {
					return user.id === +this.currentRout.snapshot.params.userFrom;
				}) || { };

				this.userTo = (this.users).find(user => {
					return user.id === +this.currentRout.snapshot.params.userTo;
				}) || { };
			}

			if (this.userFrom.id != null) {
				this.userFrom.name = this.userFrom.name || `участник_${+this.userFrom.id + 1}`;
			}
			if (this.userTo.id != null) {
				this.userTo.name = this.userTo.name || `участник_${+this.userTo.id + 1}`;
			}

			this.name = this.name ? this.name : `${this.userFrom.name} повернення коштів`;
		} catch (err) {
			this.metaInfo.showError(err);
		}
	}

	refund () {
		this.metaInfo.showPreloader(true);

		const encAmount = this.bs.convert(this.amount);

		return this.requester[this.CONFIG.requestSet](...[...this.CONFIG.paramsGet, {
			userFromId: this.userFrom.id,
			userToId:   this.userTo.id,
			amount:     encAmount,
			date:       this.date,
			name:       this.name,
		}])
		.then(() => {
			this.router.navigate(this.CONFIG.navigate);
		})
		.catch(err => {
			// todo df change after finding
			// this.metaInfo.showError(err);
			this.metaInfo.showPreloader(false);

			if (err && err.code === 'NEED_AUTH') {
				this.auth.logout();

				return this.router.navigate(['login']);
			}
		});
	}

	changeUser (event, type) {
		if (type) {
			this.userFrom = (this.users).find(user => {
				return user.id === +event.value;
			}) || { };
		} else {
			this.userTo = (this.users).find(user => {
				return user.id === +event.value;
			}) || { };
		}
	}
}
