<mat-card class="float-card">
  <mat-form-field>
    <input maxlength="25" matInput placeholder="Имя учасника" #input [(ngModel)]="formName">
  </mat-form-field>
  <button style="margin-left: 15px" mat-raised-button (click)="addUser()" title="Добавить участника" color="primary">
    <mat-icon>add</mat-icon>
  </button>
  <mat-list>
    @for (user of usersList; track user) {
      <div>
        <mat-list-item>
          <mat-icon mat-list-icon>account_circle</mat-icon>
          <h4 mat-line>{{ user.name }}</h4>
          <mat-icon (click)="editUser(user.id)">mode_edit</mat-icon>
        </mat-list-item>
        <mat-divider></mat-divider>
      </div>
    }
  </mat-list>
  <div class="first-row">
    <button mat-raised-button (click)="saveUsers()" color="primary">Зберегти</button>
  </div>
</mat-card>
